import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {ApplicantPersonaData, JsonWrappedValue, AdminTalentFeedPositionRelationData, AdminTalentPositionRelationData, AdminEmploymentPositionSpecialTargetingData, AdminPositionPerformanceFilterData, AdminEmploymentPositionData, PositionRelationFilterData, PagedData, LabelData, PositionPrivacyDisclaimerData, AdminEmploymentPositionFilterData, AdminPositionFieldOfActivityData, AdminEmploymentPositionDisplayData, EmploymentPositionBookingsData, TalentFilterData, EmploymentPositionDisplayData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminEmploymentPositionController
 */

@Injectable()
@ResourceParams({})
export class AdminEmploymentPositionResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/positions/applicantPersona/createOrUpdate',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createOrUpdateApplicantPersona: IResourceMethodObservableStrict<ApplicantPersonaData, null, null, null> | undefined
    createOrUpdateApplicantPersona(requestBody: ApplicantPersonaData): Promise<null> {
        if (!this._createOrUpdateApplicantPersona) throw new Error("resource has not been properly initialized")
        return this._createOrUpdateApplicantPersona(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/positions',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createPosition: IResourceMethodObservableStrict<AdminEmploymentPositionData, null, null, JsonWrappedValue<number>> | undefined
    createPosition(requestBody: AdminEmploymentPositionData): Promise<JsonWrappedValue<number>> {
        if (!this._createPosition) throw new Error("resource has not been properly initialized")
        return this._createPosition(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/positions/{id}/delete',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deletePosition: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    deletePosition(id: number): Promise<null> {
        if (!this._deletePosition) throw new Error("resource has not been properly initialized")
        return this._deletePosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/positions/{id}/duplicate',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _duplicateEmploymentPosition: IResourceMethodObservableStrict<null, null, {id: number}, AdminEmploymentPositionData> | undefined
    duplicateEmploymentPosition(id: number): Promise<AdminEmploymentPositionData> {
        if (!this._duplicateEmploymentPosition) throw new Error("resource has not been properly initialized")
        return this._duplicateEmploymentPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/positions/{id}/rematch',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _forceMatchPosition: IResourceMethodObservableStrict<null, null, {id: number}, null> | undefined
    forceMatchPosition(id: number): Promise<null> {
        if (!this._forceMatchPosition) throw new Error("resource has not been properly initialized")
        return this._forceMatchPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/positions/{id}/all',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllEmploymentPositions: IResourceMethodObservableStrict<null, null, {id: number}, AdminEmploymentPositionData[]> | undefined
    getAllEmploymentPositions(id: number): Promise<AdminEmploymentPositionData[]> {
        if (!this._getAllEmploymentPositions) throw new Error("resource has not been properly initialized")
        return this._getAllEmploymentPositions(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/positions/{id}/recommendations',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAllRecommendationsForPosition: IResourceMethodObservableStrict<null, {page?: number, pageSize?: number}, {id: number}, PagedData<AdminTalentFeedPositionRelationData>> | undefined
    getAllRecommendationsForPosition(id: number, requestParams?: {page?: number, pageSize?: number}): Promise<PagedData<AdminTalentFeedPositionRelationData>> {
        if (!this._getAllRecommendationsForPosition) throw new Error("resource has not been properly initialized")
        return this._getAllRecommendationsForPosition(null, requestParams || {}, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/positions/{id}/applicantPersona',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getApplicantPersonaForId: IResourceMethodObservableStrict<null, null, {id: number}, ApplicantPersonaData> | undefined
    getApplicantPersonaForId(id: number): Promise<ApplicantPersonaData> {
        if (!this._getApplicantPersonaForId) throw new Error("resource has not been properly initialized")
        return this._getApplicantPersonaForId(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/positions/state',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPositionByState: IResourceMethodObservableStrict<null, {state: ('Draft' | 'Inactive' | 'SearchableOnly' | 'Active')}, null, AdminEmploymentPositionData[]> | undefined
    getEmploymentPositionByState(requestParams: {state: ('Draft' | 'Inactive' | 'SearchableOnly' | 'Active')}): Promise<AdminEmploymentPositionData[]> {
        if (!this._getEmploymentPositionByState) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPositionByState(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/positions/query',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getEmploymentPositionsByQuery: IResourceMethodObservableStrict<null, {q?: string, companyId?: number, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<AdminEmploymentPositionDisplayData>> | undefined
    getEmploymentPositionsByQuery(requestParams?: {q?: string, companyId?: number, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<AdminEmploymentPositionDisplayData>> {
        if (!this._getEmploymentPositionsByQuery) throw new Error("resource has not been properly initialized")
        return this._getEmploymentPositionsByQuery(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/positions/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFilteredEmploymentPositions: IResourceMethodObservableStrict<AdminEmploymentPositionFilterData, {page?: number, pageSize?: number, sortAsc?: boolean}, null, PagedData<AdminEmploymentPositionDisplayData>> | undefined
    getFilteredEmploymentPositions(requestBody: AdminEmploymentPositionFilterData, requestParams?: {page?: number, pageSize?: number, sortAsc?: boolean}): Promise<PagedData<AdminEmploymentPositionDisplayData>> {
        if (!this._getFilteredEmploymentPositions) throw new Error("resource has not been properly initialized")
        return this._getFilteredEmploymentPositions(requestBody, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/positions/fieldEditor/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getFilteredEmploymentPositionsForActivityFieldEditor: IResourceMethodObservableStrict<AdminPositionPerformanceFilterData, {page: number, pageSize: number}, null, PagedData<AdminPositionFieldOfActivityData>> | undefined
    getFilteredEmploymentPositionsForActivityFieldEditor(requestBody: AdminPositionPerformanceFilterData, requestParams: {page: number, pageSize: number}): Promise<PagedData<AdminPositionFieldOfActivityData>> {
        if (!this._getFilteredEmploymentPositionsForActivityFieldEditor) throw new Error("resource has not been properly initialized")
        return this._getFilteredEmploymentPositionsForActivityFieldEditor(requestBody, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/positions/{id}/labeldata',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getLabelDataForId: IResourceMethodObservableStrict<null, null, {id: number}, LabelData> | undefined
    getLabelDataForId(id: number): Promise<LabelData> {
        if (!this._getLabelDataForId) throw new Error("resource has not been properly initialized")
        return this._getLabelDataForId(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/positions/{id}/privacy/disclaimer/pdf',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getLatestPrivacyDisclaimerAsPdf: IResourceMethodObservableStrict<null, null, {id: number}, Blob> | undefined
    getLatestPrivacyDisclaimerAsPdf(id: number): Promise<Blob> {
        if (!this._getLatestPrivacyDisclaimerAsPdf) throw new Error("resource has not been properly initialized")
        return this._getLatestPrivacyDisclaimerAsPdf(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/positions/{id}/privacy/disclaimer',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getLatestPrivacyDisclaimerInformation: IResourceMethodObservableStrict<null, null, {id: number}, PositionPrivacyDisclaimerData> | undefined
    getLatestPrivacyDisclaimerInformation(id: number): Promise<PositionPrivacyDisclaimerData> {
        if (!this._getLatestPrivacyDisclaimerInformation) throw new Error("resource has not been properly initialized")
        return this._getLatestPrivacyDisclaimerInformation(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/positions/filters/wordsForSearchTerm',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMatchingWordsForSearchTermInTalentFilter: IResourceMethodObservableStrict<null, {searchTerms: string}, null, string[]> | undefined
    getMatchingWordsForSearchTermInTalentFilter(requestParams: {searchTerms: string}): Promise<string[]> {
        if (!this._getMatchingWordsForSearchTermInTalentFilter) throw new Error("resource has not been properly initialized")
        return this._getMatchingWordsForSearchTermInTalentFilter(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/positions/{id}/matching/state',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPositionCurrentlyInMatching: IResourceMethodObservableStrict<null, null, {id: number}, JsonWrappedValue<boolean>> | undefined
    getPositionCurrentlyInMatching(id: number): Promise<JsonWrappedValue<boolean>> {
        if (!this._getPositionCurrentlyInMatching) throw new Error("resource has not been properly initialized")
        return this._getPositionCurrentlyInMatching(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/talents/positions/prime/filter',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPrimePositionsForTalent: IResourceMethodObservableStrict<PositionRelationFilterData, null, null, AdminTalentPositionRelationData[]> | undefined
    getPrimePositionsForTalent(requestBody: PositionRelationFilterData): Promise<AdminTalentPositionRelationData[]> {
        if (!this._getPrimePositionsForTalent) throw new Error("resource has not been properly initialized")
        return this._getPrimePositionsForTalent(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/positions/{id}/prime/filters',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getPrimeTalentFiltersOfPosition: IResourceMethodObservableStrict<null, null, {id: number}, TalentFilterData[]> | undefined
    getPrimeTalentFiltersOfPosition(id: number): Promise<TalentFilterData[]> {
        if (!this._getPrimeTalentFiltersOfPosition) throw new Error("resource has not been properly initialized")
        return this._getPrimeTalentFiltersOfPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/positions/{employmentPositionId}/admin',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSingleEmploymentPositionById: IResourceMethodObservableStrict<null, null, {employmentPositionId: number}, AdminEmploymentPositionData> | undefined
    getSingleEmploymentPositionById(employmentPositionId: number): Promise<AdminEmploymentPositionData> {
        if (!this._getSingleEmploymentPositionById) throw new Error("resource has not been properly initialized")
        return this._getSingleEmploymentPositionById(null, null, {employmentPositionId: employmentPositionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/positions/{employmentPositionId}/display',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSingleEmploymentPositionDisplayById: IResourceMethodObservableStrict<null, null, {employmentPositionId: number}, EmploymentPositionDisplayData> | undefined
    getSingleEmploymentPositionDisplayById(employmentPositionId: number): Promise<EmploymentPositionDisplayData> {
        if (!this._getSingleEmploymentPositionDisplayById) throw new Error("resource has not been properly initialized")
        return this._getSingleEmploymentPositionDisplayById(null, null, {employmentPositionId: employmentPositionId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/positions/{id}/specialTargeting/filters',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSpecialTargetingFiltersOfPosition: IResourceMethodObservableStrict<null, null, {id: number}, AdminEmploymentPositionSpecialTargetingData> | undefined
    getSpecialTargetingFiltersOfPosition(id: number): Promise<AdminEmploymentPositionSpecialTargetingData> {
        if (!this._getSpecialTargetingFiltersOfPosition) throw new Error("resource has not been properly initialized")
        return this._getSpecialTargetingFiltersOfPosition(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/talent/{talentId}/primePositions/{employmentPositionId}/markAsUnsuitable',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _markPrimePositionAsUnsuitable: IResourceMethodObservableStrict<string, null, {employmentPositionId: number, talentId: number}, null> | undefined
    markPrimePositionAsUnsuitable(requestBody: string, pathVariables: {employmentPositionId: number, talentId: number}): Promise<null> {
        if (!this._markPrimePositionAsUnsuitable) throw new Error("resource has not been properly initialized")
        return this._markPrimePositionAsUnsuitable(requestBody, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admin/positions/{id}/filters/{filterId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _removeFilterFromPosition: IResourceMethodObservableStrict<null, null, {id: number, filterId: number}, null> | undefined
    removeFilterFromPosition(pathVariables: {id: number, filterId: number}): Promise<null> {
        if (!this._removeFilterFromPosition) throw new Error("resource has not been properly initialized")
        return this._removeFilterFromPosition(null, null, pathVariables).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/positions/{id}/bookings',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateBookings: IResourceMethodObservableStrict<EmploymentPositionBookingsData, null, {id: number}, null> | undefined
    updateBookings(requestBody: EmploymentPositionBookingsData, id: number): Promise<null> {
        if (!this._updateBookings) throw new Error("resource has not been properly initialized")
        return this._updateBookings(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/positions/{id}/update',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updatePosition: IResourceMethodObservableStrict<AdminEmploymentPositionData, null, {id: number}, null> | undefined
    updatePosition(requestBody: AdminEmploymentPositionData, id: number): Promise<null> {
        if (!this._updatePosition) throw new Error("resource has not been properly initialized")
        return this._updatePosition(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/positions/{id}/privacy/disclaimer',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updatePrivacyDisclaimer: IResourceMethodObservableStrict<PositionPrivacyDisclaimerData, null, {id: number}, null> | undefined
    updatePrivacyDisclaimer(requestBody: PositionPrivacyDisclaimerData, id: number): Promise<null> {
        if (!this._updatePrivacyDisclaimer) throw new Error("resource has not been properly initialized")
        return this._updatePrivacyDisclaimer(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/positions/{id}/specialTargeting/filters',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateSpecialTargetingFiltersForPosition: IResourceMethodObservableStrict<AdminEmploymentPositionSpecialTargetingData, null, {id: number}, null> | undefined
    updateSpecialTargetingFiltersForPosition(requestBody: AdminEmploymentPositionSpecialTargetingData, id: number): Promise<null> {
        if (!this._updateSpecialTargetingFiltersForPosition) throw new Error("resource has not been properly initialized")
        return this._updateSpecialTargetingFiltersForPosition(requestBody, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/positions/{id}/prime/filters',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _updateTalentFiltersForPosition: IResourceMethodObservableStrict<TalentFilterData[], null, {id: number}, null> | undefined
    updateTalentFiltersForPosition(requestBody: TalentFilterData[], id: number): Promise<null> {
        if (!this._updateTalentFiltersForPosition) throw new Error("resource has not been properly initialized")
        return this._updateTalentFiltersForPosition(requestBody, null, {id: id}).toPromise()
    }

}