import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {AdminKomboIntegrationData, AdminKomboImportJobData, AdminKomboMagicLinkData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.admin.AdminKomboController
 */

@Injectable()
@ResourceParams({})
export class AdminKomboResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/kombo/createLink/{companyId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createConnectionLink: IResourceMethodObservableStrict<null, null, {companyId: number}, AdminKomboMagicLinkData> | undefined
    createConnectionLink(companyId: number): Promise<AdminKomboMagicLinkData> {
        if (!this._createConnectionLink) throw new Error("resource has not been properly initialized")
        return this._createConnectionLink(null, null, {companyId: companyId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/admin/kombo/createReconnectionLink/{companyId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createReconnectionLink: IResourceMethodObservableStrict<null, null, {companyId: number}, AdminKomboMagicLinkData> | undefined
    createReconnectionLink(companyId: number): Promise<AdminKomboMagicLinkData> {
        if (!this._createReconnectionLink) throw new Error("resource has not been properly initialized")
        return this._createReconnectionLink(null, null, {companyId: companyId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Delete,
        path: '/admin/kombo/deleteIntegration/{companyId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _deleteIntegration: IResourceMethodObservableStrict<null, null, {companyId: number}, null> | undefined
    deleteIntegration(companyId: number): Promise<null> {
        if (!this._deleteIntegration) throw new Error("resource has not been properly initialized")
        return this._deleteIntegration(null, null, {companyId: companyId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/kombo/integrationInfo/{companyId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getIntegrationInfo: IResourceMethodObservableStrict<null, null, {companyId: number}, AdminKomboIntegrationData> | undefined
    getIntegrationInfo(companyId: number): Promise<AdminKomboIntegrationData> {
        if (!this._getIntegrationInfo) throw new Error("resource has not been properly initialized")
        return this._getIntegrationInfo(null, null, {companyId: companyId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/kombo/{companyId}/getJobs',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getJobs: IResourceMethodObservableStrict<null, null, {companyId: number}, AdminKomboImportJobData[]> | undefined
    getJobs(companyId: number): Promise<AdminKomboImportJobData[]> {
        if (!this._getJobs) throw new Error("resource has not been properly initialized")
        return this._getJobs(null, null, {companyId: companyId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/admin/kombo/magigLink/{companyId}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMagicLinkForCompany: IResourceMethodObservableStrict<null, null, {companyId: number}, AdminKomboMagicLinkData> | undefined
    getMagicLinkForCompany(companyId: number): Promise<AdminKomboMagicLinkData> {
        if (!this._getMagicLinkForCompany) throw new Error("resource has not been properly initialized")
        return this._getMagicLinkForCompany(null, null, {companyId: companyId}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/admin/kombo/{companyId}/importJobs',
        endpoint: 'com.uniwunder.talentagent.main',
        keepEmptyBody: true
    })
    private _importJobsForCompany: IResourceMethodObservableStrict<AdminKomboImportJobData[], null, {companyId: number}, null> | undefined
    importJobsForCompany(requestBody: AdminKomboImportJobData[], companyId: number): Promise<null> {
        if (!this._importJobsForCompany) throw new Error("resource has not been properly initialized")
        return this._importJobsForCompany(requestBody, null, {companyId: companyId}).toPromise()
    }

}