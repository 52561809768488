import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {PublicBundleResource} from "../generated/hid/resources";
import {MediaService} from "./utility/services/media.service";
import {environment} from "../environments/environment";
import {AnalyticsService} from "./utility/services/gdprconsent/analytics.service";
import {LocalStorageService} from "./utility/services/local-storage.service";
import {NavbarService} from "./utility/services/navbar.service";
import {UtmService} from "./utility/services/utm.service";
import {DemodeskService} from "./general/demo-booking/demodesk.service";
import {ViewportScroller} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit{
  title = 'frontend-public-new';
  showNavbar = true;

  constructor(
    private hidhome: PublicBundleResource,
    public mediaService: MediaService,
    public router: Router,
    private analyticsService: AnalyticsService,
    private localStorageService: LocalStorageService,
    private navbarService: NavbarService,
    private utmService: UtmService,
    private demodeskService: DemodeskService,
    private viewportScroller: ViewportScroller
  ) {
      this.utmService.startUtmParamsSubscription()

    //cannot use it with scrollPositionRestoration in app-routing module because of scrollToSection() in company.component.ts
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!router.url.includes('scrollTo')) window.scrollTo(0, 0)
      }
    })
  }

    ngAfterViewInit(): void {
        this.analyticsService.setVarifyEventCookie()
        setInterval(() => {
            this.analyticsService.setVarifyEventCookie()
        }, 3000)
    }

  ngOnInit() {

    if (this.localStorageService.getGDPRConsent().consent_tiktok) this.localStorageService.addTikTokScriptToHead()
    if(this.localStorageService.getGDPRConsent().consent_hotjar) this.localStorageService.addHotjarScriptToHead()

    this.localStorageService.updateSessionTracking()
    this.navbarService.navBarStatus.subscribe((status: boolean) => {
      this.showNavbar = status
    })
  }

  routeToSocialMedia(url: string) {
    window.open(url, "_blank")
  }

  routeToTalentAgent() {
    window.open(environment.talentAgentFrontendUrl + this.utmService.getUtmParamsAsString())
  }

  routeToTalentAgentOb(context: string) {
    this.analyticsService.reportCheckoutTrackingEvent('InitiateCheckout', null, context)
    window.open(environment.talentAgentFrontendUrl +  '/onboarding' + this.utmService.getUtmParamsAsString())
  }
  routeToCompanyLogin(){
    window.open(environment.companyFrontendUrl + this.utmService.getUtmParamsAsString())
  }

  routeToCompanyOnboarding() {
    this.analyticsService.reportCompanyOnboarding("Footer")
    window.open(environment.companyFrontendUrl +  '/onboarding' + this.utmService.getUtmParamsAsString())
  }

  routeToHID() {
    window.open("https://hochschulinitiative-deutschland.de/home")
  }

  routeToDemoDesk(context: string) {
      this.demodeskService.navToDemodesk(context)
  }

  routeToUniwunder() {
    window.open("https://uniwunder.com/")
  }



}
