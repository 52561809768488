import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PublicCareerArticleResource} from "../../../../generated/hid/resources";
import {CareerArticleData, CareerArticleSplitData} from "../../../../generated/hid/data";
import {FileService} from "../../../utility/services/file.service";
import {MediaService} from "../../../utility/services/media.service";
import {AnalyticsService} from "../../../utility/services/gdprconsent/analytics.service";
import {environment} from "../../../../environments/environment";
import {AnimationsService} from "../../../utility/services/animations.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-career-guide-detail',
  templateUrl: './career-guide-detail.component.html',
  styleUrl: './career-guide-detail.component.scss'
})
export class CareerGuideDetailComponent implements OnInit, OnDestroy {

  routingUrl: string | null = null
  careerGuideSplit: CareerArticleSplitData = <CareerArticleSplitData>{}
  otherGuides: CareerArticleData[] = []
  guideBanner: any = null
  tableOfContents: string[] = []

  routeSubscription: Subscription

  constructor(
    private route: ActivatedRoute,
    private guideResource: PublicCareerArticleResource,
    private fileService: FileService,
    public mediaService: MediaService,
    private router: Router,
    private analyticsService: AnalyticsService,
    private animationsService: AnimationsService
  ) {

    this.routeSubscription = this.route.paramMap.subscribe(params => {
      this.routingUrl = params.get('routingUrl')
      this.loadCareerGuide()
      this.loadBannerImage()
    })
  }

  ngOnInit() {
    this.loadCareerGuide()
    this.loadOtherGuides()
    this.handleAnimations()
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe()
  }

  loadCareerGuide() {
    this.guideResource.getArticleSplit(this.routingUrl).then(result => {
      this.careerGuideSplit = result
      this.loadBannerImage()
      this.getTableOfContents()
    })
  }

  loadOtherGuides() {
    this.guideResource.getAllArticles({page: 0, pageSize: 3}).then(result => {
      this.otherGuides = result.content
    })
  }

  loadBannerImage() {
    let sub = this.fileService.getCareerGuideBanner(this.careerGuideSplit.routingUrl).subscribe(next => {
      this.guideBanner = URL.createObjectURL(next)
      sub.unsubscribe()
    })
  }

  getTableOfContents() {
    this.tableOfContents = this.careerGuideSplit.articleContents.filter(content => content.type == 'CustomContent').
    map(content => content.headline)
  }

  routeToAllGuides() {
    this.router.navigateByUrl('/talent/karriereguide/all')
  }

  routeToTalentAgent() {
    this.analyticsService.reportCareerGuideCtaClick(false, this.careerGuideSplit.id)
    window.open(environment.talentAgentFrontendUrl + '/onboarding?utm_source=talentagent&utm_medium=karriereguide&utm_campaign=proposal-box', "_blank")
  }

  handleAnimations() {
    this.animationsService.handleOnScrollAnimation(".content-container", "show", false)
    this.animationsService.handleOnScrollAnimation(".guides", "jobSlide", true)
  }

}
