<div class="flex column center gap-xl content-padding">
    <div class="content-width card-shadow">


        <h1>Datenschutzerklärung</h1>
        <h1>1. Einleitung</h1>
        Mit den folgenden Informationen möchten wir Ihnen als "betroffener Person" einen Überblick über die Verarbeitung
        Ihrer personenbezogenen Daten durch uns und Ihre Rechte aus den Datenschutzgesetzen geben. Eine Nutzung unserer
        Internetseiten ist grundsätzlich ohne Eingabe personenbezogener Daten möglich. Sofern Sie besondere Services
        unseres Unternehmens über unsere Internetseite in Anspruch nehmen möchten, könnte jedoch eine Verarbeitung
        personenbezogener Daten erforderlich werden. Ist die Verarbeitung personenbezogener Daten erforderlich und
        besteht für eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell eine Einwilligung von Ihnen
        ein.
        <br>
        <br>
        Die Verarbeitung von personenbezogenen Daten, beispielsweise Ihres Namens, der Anschrift oder E-Mail-Adresse,
        erfolgt stets im Einklang mit der Datenschutz-Grundverordnung (DS-GVO) und in Übereinstimmung mit den für die
        "Uniwunder GmbH" geltenden landesspezifischen Datenschutzbestimmungen. Mittels dieser Datenschutzerklärung
        möchten wir Sie über Umfang und Zweck der von uns erhobenen, genutzten und verarbeiteten personenbezogenen Daten
        informieren.
        <br>
        <br>
        Wir haben als für die Verarbeitung Verantwortliche zahlreiche technische und organisatorische Maßnahmen
        umgesetzt, um einen möglichst lückenlosen Schutz der über diese Internetseite verarbeiteten personenbezogenen
        Daten sicherzustellen. Dennoch können internetbasierte Datenübertragungen grundsätzlich Sicherheitslücken
        aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem Grund steht es Ihnen frei,
        personenbezogene Daten auch auf alternativen Wegen, beispielsweise telefonisch oder per Post, an uns zu
        übermitteln.
        <br>
        <br>
        <h1>2. Verantwortlicher</h1>
        Verantwortlicher im Sinne der DSGVO ist die:
        <br>
        <br>
        Uniwunder GmbH
        Bergstraße 29, 01069 Dresden, Deutschland
        <br>
        Telefon: +49 (0) 351 799 033 84
        E-Mail: <a href="mailto:info@uniwunder.com">info&#64;uniwunder.com</a>
        <br>
        <br>
        <h1>3. Datenschutzbeauftragter</h1>
        Kontakt zu unserem Datenschutzbeauftragten können Sie über dieses <a
            href="https://datenconsulting.de/anfragen-datenschutzbeauftragten/">FORMULAR</a> oder über die oben
        genannten Kontaktdaten z. Hd. d. Datenschutzbeauftragten aufnehmen. Betroffene Personen können sich jederzeit
        bei allen Fragen und Anregungen zum Datenschutz an uns oder unseren Datenschutzbeauftragten wenden.
        <br>
        <br>
        <h1>4. Rechtsgrundlage der Verarbeitung</h1>
        Art. 6 Abs. 1 lit. a DS-GVO (i.V.m. § 15 Abs. 3 TMG) dient unserem Unternehmen als Rechtsgrundlage für
        Verarbeitungsvorgänge, bei denen wir eine Einwilligung für einen bestimmten Verarbeitungszweck einholen.
        <br>
        <br>
        Ist die Verarbeitung personenbezogener Daten zur Erfüllung eines Vertrags, dessen Vertragspartei Sie sind,
        erforderlich, wie dies beispielsweise bei Verarbeitungsvorgängen der Fall ist, die für eine Lieferung von Waren
        oder die Erbringung einer sonstigen Leistung oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf
        Art. 6 Abs. 1 lit. b DS-GVO. Gleiches gilt für solche Verarbeitungsvorgänge die zur Durchführung
        vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen von Anfragen zu unseren Produkten oder Leistungen.
        <br>
        <br>
        Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch welche eine Verarbeitung von
        personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher Pflichten, so basiert
        die Verarbeitung auf Art. 6 Abs. 1 lit. c DS-GVO.
        <br>
        <br>
        In seltenen Fällen könnte die Verarbeitung von personenbezogenen Daten erforderlich werden, um lebenswichtige
        Interessen der betroffenen Person oder einer anderen natürlichen Person zu schützen. Dies wäre beispielsweise
        der Fall, wenn ein Besucher in unserem Betrieb verletzt werden würde und daraufhin sein Name, sein Alter, seine
        Krankenkassendaten oder sonstige lebenswichtige Informationen an einen Arzt, ein Krankenhaus oder sonstige
        Dritte weitergegeben werden müssten. Dann würde die Verarbeitung auf Art. 6 Abs. 1 lit. d DS-GVO beruhen.
        <br>
        <br>
        Letztlich könnten Verarbeitungsvorgänge auf Art. 6 Abs. 1 lit. f DS-GVO beruhen. Auf dieser Rechtsgrundlage
        basieren Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen erfasst werden, wenn die
        Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines Dritten erforderlich ist,
        sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen. Solche
        Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den Europäischen Gesetzgeber
        besonders erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes Interesse anzunehmen sein
        könnte, wenn Sie ein Kunde unseres Unternehmens sind (Erwägungsgrund 47 Satz 2 DS-GVO).
        <br>
        <br>
        <h1>5. Übermittlung von Daten an Dritte</h1>
        Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet
        nicht statt.
        <br>
        <br>
        Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
        <br>
        <ol>
            <li>Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DS-GVO ausdrückliche Einwilligung dazu erteilt haben,</li>
            <li>die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DS-GVO zur Wahrung unserer berechtigten Interessen
                zulässig ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an
                der Nichtweitergabe Ihrer Daten haben,
            </li>
            <li>für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DS-GVO eine gesetzliche
                Verpflichtung besteht, sowie
            </li>
            <li>dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DS-GVO für die Abwicklung von
                Vertragsverhältnissen mit Ihnen erforderlich ist.
            </li>
        </ol>
        <br>
        Um Ihre Daten zu schützen und uns gegebenenfalls eine Datenübermittlung in Drittländer (außerhalb der EU/des
        EWR)zu ermöglichen, haben wir Vereinbarungen zur Auftragsverarbeitung auf Grundlage der Standardvertragsklauseln
        der Europäischen Kommission abgeschlossen.
        <br>
        <br>
        <h1>6. Technik</h1>
        <br>
        <h2>6.1 SSL/TLS-Verschlüsselung</h2>
        Diese Seite nutzt zur Gewährleistung der Sicherheit der Datenverarbeitung und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen, Login-Daten oder Kontaktanfragen, die Sie an uns als
        Betreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass in
        der Adresszeile des Browsers statt einem "http://" ein "https://" steht und an dem Schloss-Symbol in Ihrer
        Browserzeile.
        <br>
        <br>
        Wir setzen diese Technologie ein, um Ihre übermittelten Daten zu schützen.
        <br>
        <br>
        <h2>6.2 Datenerfassung beim Besuch der Internetseite</h2>
        <br>
        Bei der lediglich informatorischen Nutzung unserer Website, also wenn Sie sich nicht registrieren oder uns
        anderweitig Informationen übermitteln, erheben wir nur solche Daten, die Ihr Browser an unseren Server
        übermittelt (in sog. "Server-Logfiles"). Unsere Internetseite erfasst mit jedem Aufruf einer Seite durch Sie
        oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Diese allgemeinen Daten und
        Informationen werden in den Logfiles des Servers gespeichert. Erfasst werden können die
        <br>
        <ol>
            <li>verwendeten Browsertypen und Versionen,</li>
            <li>das vom zugreifenden System verwendete Betriebssystem,</li>
            <li>die Internetseite, von welcher ein zugreifendes System auf unsere Internetseite gelangt (sogenannte
                Referrer),
            </li>
            <li>die Unterwebseiten, welche über ein zugreifendes System auf unserer Internetseite angesteuert werden,
            </li>
            <li>das Datum und die Uhrzeit eines Zugriffs auf die Internetseite,</li>
            <li>eine gekürzte Internet-Protokoll-Adresse (anonymisierte IP-Adresse),</li>
            <li>der Internet-Service-Provider des zugreifenden Systems.</li>
        </ol>
        <br>
        Bei der Nutzung dieser allgemeinen Daten und Informationen ziehen wir keine Rückschlüsse auf Ihre Person. Diese
        Informationen werden vielmehr benötigt, um
        <br>
        <ol>
            <li>die Inhalte unserer Internetseite korrekt auszuliefern,</li>
            <li>die Inhalte unserer Internetseite sowie die Werbung für diese zu optimieren,</li>
            <li>die dauerhafte Funktionsfähigkeit unserer IT-Systeme und der Technik unserer Internetseite zu
                gewährleisten sowie
            </li>
            <li>um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur Strafverfolgung notwendigen
                Informationen bereitzustellen.
            </li>
        </ol>
        <br>
        Diese erhobenen Daten und Informationen werden durch uns daher einerseits statistisch und ferner mit dem Ziel
        ausgewertet, den Datenschutz und die Datensicherheit in unserem Unternehmen zu erhöhen, um letztlich ein
        optimales Schutzniveau für die von uns verarbeiteten personenbezogenen Daten sicherzustellen. Die anonymen Daten
        der Server-Logfiles werden getrennt von allen durch eine betroffene Person angegebenen personenbezogenen Daten
        gespeichert.
        <br>
        <br>
        Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Unser berechtigtes Interesse
        folgt aus oben aufgelisteten Zwecken zur Datenerhebung.
        <br>
        <br>
        <h2>6.3 Hosting durch Microsoft Azure Cloud</h2>
        <br>
        Wir hosten unsere Website bei der Microsoft Corporation, One Microsoft Way, 98052-6399 Redmond WA, Vereinigte
        Staaten von Amerika (Microsoft Azure). Wenn Sie unsere Website besuchen, werden Ihre personenbezogenen Daten
        (z.B. IP-Adressen in Logfiles) auf den Servern von Microsoft verarbeitet. Diese Server befinden sich in
        Frankfurt am Main.
        <br>
        <br>
        Die Verwendung von Microsoft erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f) DS-GVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung und Bereitstellung sowie Absicherung unserer Website.
        <br>
        <br>
        Microsoft Corporation hat sich im Rahmen des EU-US-Privacy-Shield-Abkommens selbst zertifiziert (vgl. <a href="https://www.privacyshield.gov/list">https://www.privacyshield.gov/list</a>).
        <br>
        <br>
        Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO. Das berechtigte Interesse besteht in einer fehlerfreien Funktion der Internetseite.
        <br>
        <br>
        Weitere Informationen zur Microsoft Azure Cloud finden Sie in der Datenschutzerklärung von Microsoft: <a href="https://www.microsoft.com/de-de/trust-center">https://www.microsoft.com/de-de/trust-center</a>.
        <br>
        <br>
        <h1>7. Cookies</h1>
        <br>
        <h2>7.1 Allgemeines zu Cookies</h2>
        Wir setzen auf unserer Internetseite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser
        automatisch erstellt und die auf Ihrem IT-System (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie
        unsere Seite besuchen.
        <br>
        <br>

        In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten
        Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität
        erhalten.
        <br>
        <br>

        Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie angenehmer zu gestalten. So
        setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits
        besucht haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.
        <br>
        <br>

        Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit temporäre Cookies ein, die für
        einen bestimmten festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut,
        um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche
        Eingaben und Einstellungen Sie getätigt haben, um diese nicht noch einmal eingeben zu müssen.
        <br>
        <br>

        Zum anderen setzten wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der
        Optimierung unseres Angebotes für Sie auszuwerten. Diese Cookies ermöglichen es uns, bei einem erneuten Besuch
        unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils
        definierten Zeit automatisch gelöscht.
        <br>
        <br>

        <h2>7.2 Rechtsgrundlage für die Verwendung von Cookies</h2>
        Die durch Cookies verarbeiteten Daten, die für die ordnungsmäßige Funktion der Webseite benötigt werden, sind
        damit zur Wahrung unserer berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit. f DS-GVO
        erforderlich.
        <br>
        <br>

        Für alle anderen Cookies gilt, dass Sie über unser Opt-in Cookie-Banner Ihre Einwilligung hierzu im Sinne von
        Art. 6 Abs. 1 lit. a DS-GVO abgegeben haben.
        <br>
        <br>

        <h1>8. Kontaktaufnahme und Terminbuchung</h1>
        <br>
        <h2>8.1 Kontaktaufnahme / Kontaktformular</h2>
        Im Rahmen der Kontaktaufnahme mit uns (z.B. per Kontaktformular oder E-Mail) werden personenbezogene Daten erhoben. Welche Daten im Falle der Nutzung eines Kontaktformulars erhoben werden, ist aus dem jeweiligen Kontaktformular ersichtlich. Diese Daten werden ausschließlich zum Zwecke der Beantwortung Ihres Anliegens bzw. für die Kontaktaufnahme und die damit verbundene technische Administration gespeichert und verwendet. Rechtsgrundlage für die Verarbeitung der Daten ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 lit. f) DS-GVO. Zielt Ihre Kontaktaufnahme auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 lit. b) DS-GVO. Ihre Daten werden nach abschließender Bearbeitung Ihrer Anfrage gelöscht, dies ist der Fall, wenn sich aus den Umständen entnehmen lässt, dass der betroffene Sachverhalt abschließend geklärt ist und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
        <br>
        <br>
        <h2>8.2 Terminbuchung</h2>
        Sie können über unsere Webseite einen Termin für ein unverbindliches Gespräch buchen. Dazu setzen wir den Dienst der Firma Demodesk GmbH, Isartorplatz 8, 80331 München ein. Wir verwenden Demodesk, um Interessenten eine automatisierte Buchung von Terminen zu ermöglichen. Weiterhin nutzen wir Demodesk für die Durchführung der Videokonferenzen. Neben dem Wunschtermin und Unternehmensnamen werden darüber hinaus folgende personenbezogene Daten durch Demodesk verarbeitet
        <br>
        <ul>
            <li>Vor- und Nachname</li>
            <li>E-Mail-Adresse</li>
            <li>ggf. Telefonnummer</li>
        </ul>
        Rechtsgrundlage für die Verarbeitung der Daten ist unser berechtigtes Interesse ein unverbindliches Gespräch mit Ihnen als Interessenten an unserer Leistung gemäß Art. 6 Abs. 1 lit. f DSGVO auszumachen sowie ggf. Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage auf den Abschluss eines Vertrages abzielt. Wir haben mit Demosdesk einen Auftragsverarbeitungsvertrag nach Maßgabe von Art. 28 DSGVO geschlossen, mit welcher sich der Subunternehmer verpflichtet, personenbezogene Daten ausschließlich nach unserer Weisung zu verarbeiten. Weitere Informationen zu Demodesk erhalten Sie unter <a href="https://demodesk.com/legal/privacy-policy">https://demodesk.com/legal/privacy-policy</a>.
        <br>
        <br>
        Bitte beachten Sie, dass im Falle der Kommunikation durch Terminvereinbarung Ihre Kontaktdaten (Name, E-Mail-Adresse, Unternehmensname, ggf. Telefonnummer) und das Datum des mit Ihnen vereinbarten Termins in unserem Customer-Relationship-Management-System gespeichert werden können, wenn wir ein berechtigtes wirtschaftliches Interesse daran haben, im Rahmen des Geschäftsverkehrs entstandenen Kontakt auch über den Erstkontakt hinaus zu pflegen und zum Aufbau oder zur Aufrechterhaltung einer Geschäftsbeziehung zu nutzen. Rechtsgrundlage ist hierfür Art. 6 Abs. 1 lit. f DSGVO. Der Verarbeitung innerhalb des CRM-Systems können Sie jederzeit unentgeltlich widersprechen.
        <br>
        <br>

        <h1>9. Unsere Aktivitäten in sozialen Netzwerken</h1>
        Damit wir auch in sozialen Netzwerken mit Ihnen kommunizieren und über unsere Leistungen informieren können,
        sind wir dort mit eigenen Seiten vertreten. Wenn Sie eine unserer Social Media Seiten besuchen, sind wir
        hinsichtlich der dadurch ausgelösten Verarbeitungsvorgänge, welche personenbezogene Daten betreffen, mit dem
        Anbieter der jeweiligen Social-Media-Plattform gemeinsam verantwortlich im Sinne des Art. 26 DS-GVO.
        <br>
        <br>

        Wir sind dabei nicht der originäre Anbieter dieser Seiten, sondern nutzen diese lediglich im Rahmen der uns
        gebotenen Möglichkeiten der jeweiligen Anbieter.
        <br>
        Daher weisen wir vorsorglich darauf hin, dass Ihre Daten möglicherweise auch außerhalb der Europäischen Union
        bzw. des Europäischen Wirtschaftsraumes verarbeitet werden. Eine Nutzung kann daher Datenschutzrisiken für Sie
        haben, da die Wahrung Ihrer Rechte z.B. auf Auskunft, Löschung, Widerspruch, etc. erschwert sein kann und die
        Verarbeitung in den sozialen Netzwerken häufig direkt zu Werbezwecken oder zur Analyse des Nutzerverhaltens
        durch die Anbieter erfolgt, ohne dass dies durch uns beeinflusst werden kann. Werden durch den Anbieter
        Nutzungsprofile angelegt, werden dabei häufig Cookies eingesetzt bzw. das Nutzungsverhalten direkt Ihrem eigenen
        Mitgliedprofil der sozialen Netzwerke zugeordnet (sofern Sie hier eingeloggt sind).
        <br>
        <br>

        Die beschriebenen Verarbeitungsvorgänge personenbezogener Daten erfolgen gemäß Art. 6 Abs. 1 lit. f DS-GVO auf
        Basis unseres berechtigten Interesses und des berechtigten Interesses des jeweiligen Anbieters, um mit Ihnen in
        einer zeitgemäßen Art und Weise kommunizieren bzw. Sie über unsere Leistungen informieren zu können. Müssen Sie
        bei den jeweiligen Anbietern eine Einwilligung zur Datenverarbeitung als Nutzer abgeben, bezieht sich die
        Rechtsgrundlage auf Art. 6 Abs. 1 lit. a DS-GVO i.V.m. Art. 7 DS-GVO.
        <br>
        <br>

        Da wir keine Zugriffe auf die Datenbestände der Anbieter haben, weisen wir darauf hin, dass Sie Ihre Rechte
        (z.B. auf Auskunft, Berichtigung, Löschung, etc.) am besten direkt bei dem jeweiligen Anbieter gelten machen.
        Weiterführende Informationen zur Verarbeitung Ihrer Daten in den sozialen Netzwerken und der Möglichkeit von
        Ihrem Widerspruchs- bzw. Widerrufsrecht (sog. Opt-Out) gebrauch zu machen, haben wir nachfolgend bei dem
        jeweiligen von uns eingesetzten Anbieter sozialer Netzwerke aufgeführt:
        <br>
        <br>

        <h2>9.1 Facebook</h2>
        (Mit-) Verantwortlicher für die Datenverarbeitung in Europa:
        <br>
        Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
        <br>
        <br>

        Datenschutzerklärung (Datenrichtlinie):
        <br>
        <a href="https://www.facebook.com/about/privacy">https://www.facebook.com/about/privacy</a>
        <br>
        <br>

        Opt-Out und Werbeeinstellungen:
        <br>
        <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen">https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a>
        <br>
        <a href="https://de-de.facebook.com/about/privacy/">https://de-de.facebook.com/about/privacy/</a>
        <br>
        <br>

        <h2>9.2 Instagram</h2>
        (Mit-) Verantwortlicher für die Datenverarbeitung in Deutschland:
        <br>
        Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
        <br>
        <br>

        Datenschutzerklärung (Datenrichtlinie):
        <br>
        <a href="http://instagram.com/legal/privacy/">http://instagram.com/legal/privacy/</a>
        <br>
        <br>

        Opt-Out und Werbeeinstellungen:
        <br>
        <a href="https://www.instagram.com/accounts/privacy_and_security/">https://www.instagram.com/accounts/privacy_and_security/</a>
        <br>
        <br>

        <h2>9.3 LinkedIn</h2>
        (Mit-) Verantwortlicher für die Datenverarbeitung in Europa:
        <br>
        LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Irland
        <br>
        <br>

        Datenschutzerklärung:
        <br>
        <a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a>
        <br>
        <br>

        Opt-Out und Werbeeinstellungen:
        <br>
        <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>
        <br>
        <br>

        <h2>9.4 YouTube</h2>
        (Mit-) Verantwortlicher für die Datenverarbeitung in Europa:
        <br>
        Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland
        <br>
        <br>

        Datenschutzerklärung:
        <br>
        <a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>
        <br>
        <br>

        Opt-Out und Werbeeinstellungen:
        <br>
        <a href="https://adssettings.google.com/authenticated">https://adssettings.google.com/authenticated</a>
        <br>
        <br>

        <h2>9.5 XING</h2>
        (Mit-) Verantwortlicher für die Datenverarbeitung in Deutschland:
        <br>
        XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland
        <br>
        <br>

        Datenschutzerklärung:
        <br>
        <a href="https://privacy.xing.com/de/datenschutzerklaerung">https://privacy.xing.com/de/datenschutzerklaerung</a>
        <br>
        <br>

        Auskunftsanfragen für XING Mitglieder:
        <br>
        <a href="https://www.xing.com/settings/privacy/data/disclosure">https://www.xing.com/settings/privacy/data/disclosure</a>
        <br>
        <br>

        <h2>9.6 TikTok</h2>
        (Mit-) Verantwortlicher für die Datenverarbeitung in Europa:
        <br>
        TikTok Technology Limited, 10 Earlsfort Terrace, Dublin, D02 T380, Irland
        <br>
        <br>

        Datenschutzerklärung:
        <br>
        <a href="https://www.tiktok.com/legal/new-privacy-policy?lang=de-DE">https://www.tiktok.com/legal/new-privacy-policy?lang=de-DE</a>
        <br>
        <br>

        <h1>10. Webanalyse</h1>
        <h2>10.1 Facebook Pixel (Custom Audience)</h2>
        Diese Website verwendet den "Facebook-Pixel" der Facebook Inc., 1 Hacker Way, Menlo Park, CA 94025, USA
        ("Facebook"). Im Falle der Erteilung einer ausdrücklichen Einwilligung kann hierdurch das Verhalten von Nutzern
        nachverfolgt werden, nachdem diese eine Facebook-Werbeanzeige gesehen oder angeklickt haben. Dieses Verfahren
        dient dazu, die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke auszuwerten
        und kann dazu beitragen, zukünftige Werbemaßnahmen zu optimieren.
        <br>
        <br>

        Die erhobenen Daten sind für uns anonym, bieten uns also keine Rückschlüsse auf die Identität der Nutzer.
        Allerdings werden die Daten von Facebook gespeichert und verarbeitet, sodass eine Verbindung zum jeweiligen
        Nutzerprofil möglich ist und Facebook die Daten für eigene Werbezwecke, entsprechend der Facebook-
        Datenverwendungsrichtlinie (<a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>)
        verwenden kann. Sie können Facebook sowie dessen Partnern das Schalten von Werbeanzeigen auf und außerhalb von
        Facebook ermöglichen. Es kann ferner zu diesen Zwecken ein Cookie auf Ihrem Rechner gespeichert werden. Diese
        Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1
        lit. a DS-GVO.
        <br>
        <br>

        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6
        Abs. 1 lit. a DS-GVO.
        <br>
        <br>

        Um die Verwendung von Cookies auf Ihrem IT-System zu deaktivieren, können Sie Ihren Internetbrowser so
        einstellen, dass zukünftig keine Cookies mehr auf Ihrem IT-system abgelegt werden können bzw. bereits abgelegte
        Cookies gelöscht werden. Das Abschalten sämtlicher Cookies kann jedoch dazu führen, dass einige Funktionen auf
        unseren Internetseiten nicht mehr ausgeführt werden können. Sie können der Verwendung von Cookies durch
        Drittanbieter wie z.B. Facebook auch auf folgender Website der Digital Advertising Alliance deaktivieren: <a
            href="https://www.aboutads.info/choices/">https://www.aboutads.info/choices/</a>
        <br>
        <br>

        Darüber hinaus können Sie Cookies zur Reichweitenmessung und Werbezwecken über folgenden Webseiten deaktivieren:
        <br>
        <ol>
            <li><a href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a></li>
            <li><a href="http://www.youronlinechoices.com/uk/your-ad-choices/">http://www.youronlinechoices.com/uk/your-ad-choices/</a>
            </li>
        </ol>
        <br>
        Wir weisen Sie darauf hin, dass auch diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen.
        <br>
        <br>

        <h2>10.2 Google Analytics und Optimize</h2>
        Auf unseren Webseiten nutzen wir Google Analytics, ein Webanalysedienst der Google Ireland Limited (<a
            href="https://www.google.de/intl/de/about/">https://www.google.de/intl/de/about/</a>) (Gordon House, Barrow
        Street, Dublin 4, Irland; im Folgenden "Google"). In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile
        erstellt und Cookies (siehe Punkt "Cookies") verwendet. Die durch den Cookie erzeugten Informationen über Ihre
        Benutzung dieser Website wie
        <br>
        <ol>
            <li>Browser-Typ/-Version,</li>
            <li>verwendetes Betriebssystem,</li>
            <li>Referrer-URL (die zuvor besuchte Seite),</li>
            <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
            <li>Uhrzeit der Serveranfrage,</li>
        </ol>
        <br>
        werden an einen Server von Google in den USA übertragen und dort gespeichert. Die Informationen werden
        verwendet, um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und
        um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der
        Marktforschung und bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese
        Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte
        diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google
        zusammengeführt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking).
        <br>
        <br>
        Darüber hinaus verwenden wir die von Google Analytics erhobenen Daten im Rahmen des in Google Analytics
        intergierten Tools Google Optimize („Optimize“), um die Attraktivität, den Inhalt und die Funktionalität unserer
        Website zur erhöhen, indem wir neue Funktionen und Inhalte einem prozentualen Anteil unserer Nutzer ausspielen
        und die Nutzungsänderung statistisch auswerten. Anhand eines zusätzlichen Cookies (siehe Punkt „Cookies“)
        ermöglicht uns Optimize verschiedene Versionen einer Website sowie die Reaktionen der Nutzer auf diese
        verschiedenen Versionen zu testen. So können wir beispielsweise testen, an welcher Stelle innerhalb der Website
        eine bestimmte Information am attraktivsten ist. Das zusätzliche Cookie wird verwendet, um festzustellen, ob Sie
        an einem solchen Test teilnehmen. Gleichzeitig legt es das Ende des Tests fest.
        <br>
        <br>
        Sie können die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern;
        wir weisen jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
        vollumfänglich genutzt werden können.
        <br>
        <br>

        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6
        Abs. 1 lit. a DS-GVO.
        <br>
        <br>

        Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website
        bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie
        ein Browser-Add-on herunterladen und installieren (<a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>).
        <br>
        <br>

        Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung durch
        Google Analytics zudem verhindern, indem Sie auf den folgenden Link klicken: Google Analytics deaktivieren. Es
        wird ein Opt-out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert.
        Der Opt-out-Cookie gilt nur in diesem Browser und nur für unsere Website und wird auf Ihrem Gerät abgelegt.
        Löschen Sie die Cookies in diesem Browser, müssen Sie das Opt-out-Cookie erneut setzen.
        <br>
        <br>


        Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google
        Analytics-Hilfe (<a href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a>).
        <br>
        <br>

        <h2>10.3 LinkedIn Pixel (Insight Tag) </h2>
        Auf unsere Webseite nutzen wir LinkedIn Insights der LinkedIn Corporation, 1000 W Maude Ave Sunnyvale, CA 94085, USA (LinkedIn). Im Falle der Erteilung einer ausdrücklichen Einwilligung kann hierdurch das Verhalten von Nutzern nachverfolgt werden.
        <br>
        <br>
        Das Verfahren dient dazu, die Wirksamkeit der Werbeanzeigen für statistische und Marktforschungszwecke auszuwerten und kann dazu beitragen, zukünftige Werbemaßnahmen zu optimieren. Durch den LinkedIn Pixel erhalten wir erweiterte Informationen über die Interessenten für unsere Produkte, u.a. Jobtitel, Arbeitgeber oder die Branche, in der sie tätig sind.
        <br>
        <br>
        Beim Besuch der Website können zudem unter anderem die folgenden Daten durch den LinkedIn Pixel verarbeitet werden:
        <br>
        <br>
        <ul>
            <li>IP-Adresse </li>
            <li>Interaktionen auf unserer Website (z. B. Seitenaufrufe, Klicks, Conversions) </li>
            <li>Browser-Typ/-Version </li>
            <li>verwendetes Betriebssystem </li>
            <li>Referrer-URL (zuvor besuchte Seite) </li>
            <li>Uhrzeit der Serveranfrage </li>
        </ul>
        <br>
        <br>
        Direkte Identifikatoren werden durch LinkedIn innerhalb von sieben Tagen automatisch aus dem Datenbestand entfernt, und die Daten werden nach 180 Tagen gelöscht. Die Speicherdauer des Cookies entnehmen Sie unserer eingesetzten Consent-Lösung. Die Daten werden von LinkedIn gespeichert und verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil möglich ist.
        <br>
        <br>
        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO.
        <br>
        <br>
        LinkedIn ist unter dem EU-US Data Privacy Framework zertifiziert. Es liegt hiermit ein Angemessenheitsbeschluss gem. Art. 45 DS-GVO vor, sodass eine Übermittlung personenbezogener Daten auch ohne weitere Garantien oder zusätzliche Maßnahmen erfolgen darf.
        <br>
        <br>
        Weitere Informationen und Datenschutzbestimmungen von LinkedIn Pixel können Sie einsehen unter: <a href="https://de.linkedin.com/legal/privacy-policy">https://de.linkedin.com/legal/privacy-policy</a>.
        <br>
        <br>

        <h2>10.4 Hotjar</h2>
        Auf unsere Webseite nutzen wir Hotjar. Anbieter ist die Hotjar Ltd., Level 2, St Julians Business Centre, 3, Elia Zammit Street, St Julians STJ 1000, Malta.
        <br>
        <br>
        Hotjar ist ein Werkzeug zur Analyse Ihres Nutzerverhaltens auf dieser Website. Mit Hotjar können wir u. a. Ihre Maus- und Scrollbewegungen und Klicks aufzeichnen. Hotjar kann dabei auch feststellen, wie lange Sie mit dem Mauszeiger auf einer bestimmten Stelle verblieben sind. Aus diesen Informationen erstellt Hotjar sogenannte Heatmaps, mit denen sich feststellen lässt, welche Webseitenbereiche vom Webseitenbesucher bevorzugt angeschaut werden.
        <br>
        <br>
        Des Weiteren können wir feststellen, wie lange Sie auf einer Seite verblieben sind und wann Sie sie verlassen haben. Wir können auch feststellen, an welcher Stelle Sie Ihre Eingaben in ein Kontaktformular abgebrochen haben (sog. Conversion-Funnels). Darüber hinaus können mit Hotjar direkte Feedbacks von Webseitenbesuchern eingeholt werden. Diese Funktion dient der Verbesserung der Webangebote des Websitebetreibers.
        <br>
        <br>
        Hotjar verwendet Cookies. Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert. Sie dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Mit diesen Cookies lässt sich insbesondere feststellen, ob diese Website mit einem bestimmten Endgerät besucht wurde oder ob die Funktionen von Hotjar für den betreffenden Browser deaktiviert wurden. Hotjar-Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen.
        <br>
        <br>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
        <br>
        <br>
        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a) DS-GVO
        <br>
        <br>
        Nähere Informationen zu Hotjar finden Sie unter: <a href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar">https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar</a>.
        <br>
        <br>

        <h2>10.5 Pinterest Tag </h2>
        Auf unsere Webseite nutzen wir Pinterest-Tag der von der Pinterest Europe Ltd. Palmerston House, 2nd Floor, Fenian Street, Dublin 2, Ireland.
        <br>
        <br>
        Dadurch können Nutzern der Website im Rahmen des Besuchs des Netzwerkes Pinterest oder anderer das Verfahren ebenfalls nutzende Websites interessenbezogene Werbeanzeigen („Pinterest-Ads“) dargestellt werden. Von uns geschaltete Pinterest-Ads sollen hierdurch nur solchen Nutzern angezeigt werden, die auch ein Interesse an unseren Angeboten gezeigt haben oder aber bestimmte, von uns an Pinterest übermittelte Merkmale /Interessen aufweisen (sog. „ActALike Audiences“).
        <br>
        <br>
        Darüber hinaus dient der Einsatz des Pinterest-Tag statistischen Zwecken sowie Marktforschungszwecke, da uns dieser die Möglichkeit gibt, nachvollziehen, ob ein Nutzer nach erfolgtem Klick auf eine Pinterest-Werbeanzeige auf unsere Website weitergeleitet wurde (sog. „Conversion“). Wir verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse ist, um unsere Website für Sie interessanter zu gestalten. Ferner dient der Einsatz dem Zweck der Marktforschung.
        <br>
        <br>
        Durch eine ausdrückliche Einwilligung werden durch den Pinterest-Tag Cookies gesetzt und Informationen gehasht an Pinterest übermittelt. Der Tag sammelt Informationen wie z.B.:
        <br>
        <br>
        <ul>
            <li>Geräteinformationen</li>
            <li>Betriebssystem</li>
            <li>IP-Adresse</li>
            <li>das Verhalten auf der entsprechenden Webseite</li>
        </ul>

        <br>
        <br>
        Bei Conversions verarbeiten wir hierbei regelmäßig keine personenbezogenen Daten, da es uns nicht möglich ist, die gehashte Version Ihrer Pinterest-ID Ihnen als Person zuzuordnen; dies kann nur Pinterest. Auch erhalten wir keine Informationen zu Ihnen, wie ihren Namen, ihr Alter usw.
        <br>
        <br>
        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a DS-GVO.
        <br>
        <br>
        Nähere Informationen zu den Datenschutzbestimmungen von Pinterest finden Sie unter: <a href="https://policy.pinterest.com/de/privacy-policy">https://policy.pinterest.com/de/privacy-policy</a>.
        <br>
        <br>

        <h2>10.6 TikTok Pixel </h2>
        Auf unsere Webseite nutzen wir die Web-Attributionslösung "TikTok Pixel" welche von der TikTok Technology Limited, 10 Earlsfort Terrace, D02 T380, Co. Dublin, Dublin, D02t380, Ireland, sowie der TikTok Information Technologies UK Limited, 1 London Wall, London, EC2Y 5EB, UK betrieben wird.
        <br>
        <br>
        Durch den Einsatz des TikTok-Pixels wird es TikTok ermöglicht, das Verhalten der Besucher auf unserer Website zu verfolgen und Daten über deren Aktivitäten zu sammeln. Diese Daten können genutzt werden, um gezielte Werbekampagnen auf TikTok durchzuführen. Außerdem sammeln wir Informationen über Conversions. Dadurch erhalten wir von TikTok Daten darüber, wie viele Besucher in Folge unserer TikTok-Werbeanzeigen tatsächlich unsere Website besuchen.
        <br>
        <br>
        Durch eine ausdrückliche Einwilligung werden durch das Pixel Cookies gesetzt und Informationen gehasht an TikTok übermittelt. Das Pixel sammelt Informationen wie z.B.:
        <br>
        <br>
        <ul>
            <li>Informationen zu Anzeigen/Ereignissen</li>
            <li>Zeitstempel</li>
            <li>IP-Adresse</li>
            <li>Nutzer-Agent </li>
            <li>Cookies </li>
        </ul>

        <br>
        <br>
        Bei Conversions verarbeiten wir hierbei regelmäßig keine personenbezogenen Daten, da es uns nicht möglich ist, die gehashte Version Ihrer TikTok-ID Ihnen als Person zuzuordnen; dies kann nur TikTok. Auch erhalten wir keine Informationen zu Ihnen, wie ihren Namen, ihr Alter usw.
        <br>
        <br>
        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6 Abs. 1 lit. a DS-GVO.
        <br>
        <br>
        Nähere Informationen zu den Datenschutzbestimmungen von TikTok finden Sie unter: <a href="https://www.tiktok.com/legal/privacy-policy?lang=de">https://www.tiktok.com/legal/privacy-policy?lang=de</a>.
        <br>
        <br>
        <h2>10.7 Piwik Pro </h2>
        Auf unsere Webseite nutzen wir das Analysetool Piwik Pro des Unternehmens Piwik PRO GmbH, Knesebeckstraße 62/63, 10719 Berlin, Deutschland.
        <br>
        <br>
        Mit dieser Software werden Daten zur bedarfsgerechten Gestaltung unseres Internetauftritts und zur statistischen Auswertung der Besucherströme zu Marketing- und Optimierungszwecken gesammelt. Dabei werden auch pseudonyme Nutzungsprofile erstellt. Hierzu werden Cookies eingesetzt, die auf Ihrem Computer gespeichert werden und die eine pseudonyme Analyse der Benutzung unserer Website durch Sie ermöglichen. Die IP-Adresse wird unmittelbar nach der Erfassung und vor ihrer Speicherung gekürzt. Piwik PRO Marketing Suite Cloud wird auf Microsoft Azure in Deutschland gehostet.
        <br>
        <br>
        Piwik PRO analysiert die Nutzung unserer Webseite immer anonymisiert. Gibt der Nutzer seine Einwilligung für Analytics, wird die Analyse der Benutzung unserer Website pseudonymisiert angereichert. Dadurch können dann z.B. wiederkehrende Nutzer erkannt und genauere Analysen gefahren werden.
        <br>
        <br>
        Sofern eine entsprechende Einwilligung abgegeben wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser, Art. 6 Abs. 1 lit. a DSGVO.
        <br>
        <br>
        Weitere Informationen zu den Datenschutzbestimmungen von Piwik PRO finden Sie unter: <a href="https://piwikpro.de/datenschutz-sicherheit/">https://piwikpro.de/datenschutz-sicherheit/</a>
        <br>
        <br>

        <h2>10.8 Leadfeeder</h2>
        Auf unsere Webseite nutzen wir das Datenverarbeitungs-Tool von Leadfeeder, das von der Firma Liidio Oy, Mikonkatu 17 C, 00100 Helsinki, Finnland, betrieben wird. Es bietet Unternehmen Informationen zu Besuchern ihrer Website.
        <br>
        <br>
        Leadfeeder basiert auf der Erkennung von Unternehmens-IP-Adressen. Ein Tracking-Code wird auf unserer Website platziert, der es Leadfeeder dann ermöglicht, die geschäftlichen IP-Adressen unserer Website-Besucher zu identifizieren. Leadfeeder gleicht die identifizierte geschäftliche IP-Adresse mit einer globalen Datenbank von Unternehmen und Geschäftsinformationen ab.
        <br>
        <br>
        Leadfeeder identifiziert keine persönlichen IP-Adressen, Mobilgeräte oder andere Daten als die, die Unternehmen zugeordnet sind. Zusätzlich zu den Informationen über das Unternehmen stellt Leadfeeder Informationen über das Datum und die Dauer des Besuchs des Benutzers und die Webseiten, die der Benutzer besucht, zur Verfügung. Leadfeeder stellt keine Informationen über bestimmte, identifizierbare Personen zur Verfügung, die unsere Website besuchen, und verwendet auch keine Cookies, um Besucher zu identifizieren und zu verfolgen. Es werden keine Informationen über Besucher, die dynamische IP-Adressen verwenden, zur Verfügung gestellt.
        <br>
        <br>
        Die mithilfe von Leadfeeder erhaltenen Informationen ermöglichen es uns, Statistiken zu Besuchen unserer Website zu erstellen. Diese nutzen wir zur Optimierung der Webseite sowie zur Reichweitenmessung.
        <br>
        <br>
        Weitere Informationen finden Sie unter <a href="https://www.leadfeeder.com/privacy/">https://www.leadfeeder.com/privacy/</a> sowie <a href="https://www.leadfeeder.com/leadfeeder-and-gdpr/">https://www.leadfeeder.com/leadfeeder-and-gdpr/</a>.
       <br>
        <br>

        <h1>11. Plugins und andere Dienste</h1>
        <h2>11.1 Google Tag Manager</h2>
        Diese Webseite verwendet Google Tag Manager, eine cookielose Domain die keine personenbezogenen Daten erfasst.
        <br>
        <br>
        Durch dieses Tool können "Website-Tags" (d.h. Schlagwörter, welche in HTML Elemente eingebunden werden)
        implementiert und über eine Oberfläche verwaltet werden. Durch den Einsatz des Google Tag Manager können wir
        automatisiert nachvollziehen, welchen Button, Link oder welches personalisierte Bild Sie aktiv angeklickt haben
        und können sodann festhalten, welche Inhalte unserer Webseite für Sie besonders interessant sind.
        <br>
        <br>
        Das Tool sorgt zudem für die Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen. Google Tag
        Manager greift nicht auf diese Daten zu. Wenn Sie auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen
        haben, bleibt diese für alle Tracking-Tags bestehen, die mit Google Tag Manager implementiert werden.
        <br>
        <br>
        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6
        Abs. 1 lit. a DS-GVO.
        <br>
        <br>
        <h1>12. Werbung</h1>
        <h2>12.1 Google Ads (vormals AdWords)</h2>
        Unsere Website nutzt die Funktionen von Google Ads, hiermit werben wir für diese Website in den
        Google-Suchergebnissen, sowie auf Dritt-Websites. Anbieter ist die Google Ireland Limited, Gordon House, Barrow
        Street, Dublin 4, Irland ("Google"). Zu diesem Zweck setzt Google ein Cookie im Browser Ihres Endgeräts, welches
        automatisch mittels einer pseudonymen Cookie-ID und auf Grundlage der von Ihnen besuchten Seiten eine
        interessensbasierte Werbung ermöglicht.
        <br>
        <br>
        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6
        Abs. 1 lit. a DS-GVO.
        <br>
        <br>
        Eine darüberhinausgehende Datenverarbeitung findet nur statt, sofern Sie gegenüber Google zugestimmt haben, dass
        Ihr Internet- und App-Browserverlauf von Google mit Ihrem Google-Konto verknüpft wird und Informationen aus
        Ihrem Google-Konto zum Personalisieren von Anzeigen verwendet werden, die Sie im Web betrachten. Sind Sie in
        diesem Fall während des Seitenbesuchs unserer Webseite bei Google eingeloggt, verwendet Google Ihre Daten
        zusammen mit Google Analytics-Daten, um Zielgruppenlisten für geräteübergreifendes Remarketing zu erstellen und
        zu definieren. Dazu werden Ihre personenbezogenen Daten von Google vorübergehend mit Google Analytics-Daten
        verknüpft, um Zielgruppen zu bilden.
        <br>
        <br>
        Sie können die Setzung von Cookies für Anzeigenvorgaben dauerhaft deaktivieren, indem Sie das unter folgendem
        Link verfügbare Browser-Plug-in herunterladen und installieren:
        <br>
        <br>
        Alternativ können Sie sich bei der Digital Advertising Alliance unter der Internetadresse youradchoices.com über
        das Setzen von Cookies informieren und Einstellungen hierzu vornehmen. Schließlich können Sie Ihren Browser so
        einstellen, dass Sie über das Setzen von Cookies informiert werden und einzeln über deren Annahme entscheiden
        oder die Annahme von Cookies für bestimmte Fälle oder generell ausschließen. Bei der Nichtannahme von Cookies
        kann die Funktionalität unserer Website eingeschränkt sein.
        <br>
        <br>
        Google Ireland Limited mit Sitz in Irland ist für das us-europäische Datenschutzübereinkommen "Privacy Shield"
        zertifiziert, welches die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleistet.
        <br>
        <br>
        Weitergehende Informationen und die Datenschutzbestimmungen bezüglich Werbung und Google können Sie hier
        einsehen: https://www.google.com/policies/technologies/ads/
        <br>
        <br>
        <h2>12.2 Google AdSense</h2>
        Wir haben auf dieser Internetseite Google AdSense integriert. Google AdSense ist ein Online-Dienst, über welchen
        eine Vermittlung von Werbung auf Drittseiten ermöglicht wird. Google AdSense beruht auf einem Algorithmus,
        welcher die auf Drittseiten angezeigten Werbeanzeigen passend zu den Inhalten der jeweiligen Drittseite
        auswählt. Google AdSense gestattet ein interessenbezogenes Targeting des Internetnutzers, welches mittels
        Generierung von individuellen Benutzerprofilen umgesetzt wird.
        <br>
        <br>
        Betreibergesellschaft der Google-AdSense-Komponente ist die Google Ireland Limited, Gordon House, Barrow Street,
        Dublin 4, Irland.
        <br>
        <br>
        Der Zweck der Google-AdSense-Komponente ist die Einbindung von Werbeanzeigen auf unserer Internetseite.
        Google-AdSense setzt ein Cookie auf Ihrem IT-System. Mit der Setzung des Cookies wird der Alphabet Inc., 1600
        Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA, eine Analyse der Benutzung unserer Internetseite
        ermöglicht. Durch jeden Aufruf einer der Einzelseiten dieser Internetseite, die durch uns betrieben wird und auf
        welcher eine Google-AdSense-Komponente integriert wurde, wird der Internetbrowser auf Ihrem IT-System
        automatisch durch die jeweilige Google-AdSense-Komponente veranlasst, Daten zum Zwecke der Online-Werbung und
        der Abrechnung von Provisionen an die Alphabet Inc. zu übermitteln. Im Rahmen dieses technischen Verfahrens
        erhält die Alphabet Inc. Kenntnis über personenbezogene Daten, wie Ihrer IP-Adresse, die der Alphabet Inc. unter
        anderem dazu dienen, die Herkunft der Besucher und Klicks nachzuvollziehen und in der Folge
        Provisionsabrechnungen zu ermöglichen.
        <br>
        <br>
        Sie können die Setzung von Cookies durch unsere Internetseite jederzeit mittels einer entsprechenden Einstellung
        des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche
        Einstellung des genutzten Internetbrowsers würde auch verhindern, dass die Alphabet Inc. ein Cookie auf Ihrem
        IT-System setzt. Zudem kann ein von der Alphabet Inc. bereits gesetzter Cookie jederzeit über den
        Internetbrowser oder andere Softwareprogramme gelöscht werden.
        <br>
        <br>
        Google AdSense verwendet zudem sogenannte Zählpixel. Ein Zählpixel ist eine Miniaturgrafik, die in
        Internetseiten eingebettet wird, um eine Logdatei-Aufzeichnung und eine Logdatei-Analyse zu ermöglichen, wodurch
        eine statistische Auswertung durchgeführt werden kann. Anhand des eingebetteten Zählpixels kann die Alphabet
        Inc. erkennen, ob und wann eine Internetseite von Ihrem IT-System geöffnet wurde und welche Links von Ihnen
        angeklickt wurden. Zählpixel dienen unter anderem dazu, den Besucherfluss einer Internetseite auszuwerten.
        <br>
        <br>
        Über Google AdSense werden personenbezogene Daten und Informationen, was auch die IP-Adresse umfasst und zur
        Erfassung und Abrechnung der angezeigten Werbeanzeigen notwendig ist, an die Alphabet Inc. in die Vereinigten
        Staaten von Amerika übertragen. Diese personenbezogenen Daten werden in den Vereinigten Staaten von Amerika
        gespeichert und verarbeitet. Die Alphabet Inc. gibt diese über das technische Verfahren erhobenen
        personenbezogenen Daten unter Umständen an Dritte weiter.
        <br>
        <br>
        Google-AdSense wird unter diesem Link https://www.google.de/intl/de/adsense/start/ genauer erläutert.
        <br>
        <br>
        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6
        Abs. 1 lit. a DS-GVO.
        <br>
        <br>
        <h2>12.3 Google Ads mit Conversion-Tracking</h2>
        Wir haben auf dieser Internetseite Google Ads integriert. Google Ads ist ein Dienst zur Internetwerbung, der es
        Werbetreibenden gestattet, sowohl Anzeigen in den Suchmaschinenergebnissen von Google als auch im
        Google-Werbenetzwerk zu schalten. Google Ads ermöglicht es einem Werbetreibenden, vorab bestimmte
        Schlüsselwörter festzulegen, mittels derer eine Anzeige in den Suchmaschinenergebnissen von Google
        ausschließlich dann angezeigt wird, wenn der Nutzer mit der Suchmaschine ein schlüsselwortrelevantes
        Suchergebnis abruft. Im Google-Werbenetzwerk werden die Anzeigen mittels eines automatischen Algorithmus und
        unter Beachtung der zuvor festgelegten Schlüsselwörter auf themenrelevanten Internetseiten verteilt.
        <br>
        <br>
        Betreibergesellschaft der Dienste von Google Ads ist die Google Ireland Limited, Gordon House, Barrow Street,
        Dublin 4, Irland.
        <br>
        <br>
        Der Zweck von Google Ads ist die Bewerbung unserer Internetseite durch die Einblendung von interessenrelevanter
        Werbung auf den Internetseiten von Drittunternehmen und in den Suchmaschinenergebnissen der Suchmaschine Google
        und eine Einblendung von Fremdwerbung auf unserer Internetseite.
        <br>
        <br>
        Gelangen Sie über eine Google-Anzeige auf unsere Internetseite, wird auf Ihrem IT-System durch Google ein
        sogenannter Conversion-Cookie abgelegt. Ein Conversion-Cookie verliert nach dreißig Tagen seine Gültigkeit und
        dient nicht zu Ihrer Identifikation. Über den Conversion-Cookie wird, sofern das Cookie noch nicht abgelaufen
        ist, nachvollzogen, ob bestimmte Unterseiten, beispielsweise der Warenkorb von einem Online-Shop-System, auf
        unserer Internetseite aufgerufen wurden. Durch den Conversion-Cookie können sowohl wir als auch Google
        nachvollziehen, ob ein Nutzer, der über eine AdWords-Anzeige auf unsere Internetseite gelangt ist, einen Umsatz
        generierte, also einen Warenkauf vollzogen oder abgebrochen hat.
        <br>
        <br>
        Die durch die Nutzung des Conversion-Cookies erhobenen Daten und Informationen werden von Google verwendet, um
        Besuchsstatistiken für unsere Internetseite zu erstellen. Diese Besuchsstatistiken werden durch uns wiederum
        genutzt, um die Gesamtanzahl der Nutzer zu ermitteln, welche über Ads-Anzeigen an uns vermittelt wurden, also um
        den Erfolg oder Misserfolg der jeweiligen Ads-Anzeige zu ermitteln und um unsere Ads-Anzeigen für die Zukunft zu
        optimieren. Weder unser Unternehmen noch andere Werbekunden von Google-Ads erhalten Informationen von Google,
        mittels derer Sie identifiziert werden könnten.
        <br>
        <br>
        Mittels des Conversion-Cookies werden personenbezogene Informationen, beispielsweise die durch Sie besuchten
        Internetseiten, gespeichert. Bei jedem Besuch unserer Internetseiten werden demnach personenbezogene Daten,
        einschließlich der IP-Adresse des von Ihnen genutzten Internetanschlusses, an Google in den Vereinigten Staaten
        von Amerika übertragen. Diese personenbezogenen Daten werden durch Google in den Vereinigten Staaten von Amerika
        gespeichert. Google gibt diese über das technische Verfahren erhobenen personenbezogenen Daten unter Umständen
        an Dritte weiter.
        <br>
        <br>
        Sie können die Setzung von Cookies durch unsere Internetseite jederzeit mittels einer entsprechenden Einstellung
        des genutzten Internetbrowsers verhindern und damit der Setzung von Cookies dauerhaft widersprechen. Eine solche
        Einstellung des genutzten Internetbrowsers würde auch verhindern, dass Google einen Conversion-Cookie auf Ihrem
        IT-System setzt. Zudem kann ein von Google Ads bereits gesetzter Cookie jederzeit über den Internetbrowser oder
        andere Softwareprogramme gelöscht werden.
        <br>
        <br>
        Ferner besteht für Sie die Möglichkeit, der interessenbezogenen Werbung durch Google zu widersprechen. Hierzu
        müssen Sie von Ihrem genutzten Internetbrowser aus den Link www.google.de/settings/ads aufrufen und dort die
        gewünschten Einstellungen vornehmen.
        <br>
        <br>
        Diese Verarbeitungsvorgänge erfolgen ausschließlich bei Erteilung der ausdrücklichen Einwilligung gemäß Art. 6
        Abs. 1 lit. a DS-GVO.
        <br>
        <br>
        Weitere Informationen und die geltenden Datenschutzbestimmungen von Google können unter
        https://www.google.de/intl/de/policies/privacy/ abgerufen werden.
        <br>
        <br>
        <h1>13. Datenverarbeitung von Kandidaten</h1>
        <h2>13.1 Datenverarbeitung zur Aufnahme in Talentagent für Kandidaten</h2>
        Auf unserer Plattform unter <a href="www.talentagent.de">www.talentagent.de</a> (nachfolgend „Talentagent“) bieten wir Nutzern die Möglichkeit, sich unter Angabe personenbezogener Daten zu registrieren und einen eigenen Nutzer-Account zu erstellen (nachfolgend „registrierte Kandidaten“).
        <br>
        <br>
        Im Rahmen des Registrierungsprozesses über Talentagent werden Sie über die Datenverarbeitung informiert und auf diese Datenschutzerklärung verwiesen.
        <br>
        <br>
        Welche personenbezogenen Daten dabei an uns übermittelt werden, ergibt sich aus der jeweiligen Eingabemaske, die für die Registrierung verwendet wird. Die von Ihnen eingegebenen personenbezogenen Daten werden ausschließlich für die interne Verwendung bei uns und für eigene Zwecke erhoben und gespeichert. Eine Weitergabe an Dritte findet in diesem Prozess insoweit nicht statt. Es sei denn, der Nutzer gibt im Rahmen seiner Registrierung an, dass dieser Unterstützung durch einen Karrierecoach erhalten möchte (siehe hierzu Abschnitt 13.2)
        <br>
        <br>
        Durch eine Registrierung auf Talentagent wird ferner die von Ihrem Internet-Service-Provider (ISP) vergebene IP-Adresse, das Datum sowie die Uhrzeit der Registrierung gespeichert. Die Speicherung dieser Daten erfolgt vor dem Hintergrund, dass nur so der Missbrauch unserer Dienste verhindert werden kann, und diese Daten im Bedarfsfall ermöglichen, begangene Straftaten aufzuklären. Insofern ist die Speicherung dieser Daten zu unserer Absicherung erforderlich. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, sofern keine gesetzliche Pflicht zur Weitergabe besteht oder die Weitergabe der Strafverfolgung dient.
        <br>
        <br>
        Ihre Registrierung unter freiwilliger Angabe personenbezogener Daten dient uns außerdem dazu, Ihnen Inhalte oder Leistungen anzubieten, die aufgrund der Natur der Sache nur registrierten Kandidaten angeboten werden können. Registrierten Kandidaten steht die Möglichkeit frei, die bei der Registrierung angegebenen personenbezogenen Daten jederzeit abzuändern oder vollständig aus unserem dem Datenbestand löschen zu lassen. Die Löschung können Sie direkt über die Einstellungen Ihres Nutzer-Accounts anweisen.
        <br>
        <br>
        Da die Verarbeitung der Daten des Nutzers der Erfüllung eines Vertrages (oder der Durchführung vorvertraglicher Maßnahmen) dient, deren Vertragspartei der Nutzer ist, ist die Rechtsgrundlage für die Verarbeitung der Daten Art. 6 Abs. 1 lit. b DS-GVO.
        <br>
        <br>
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Dies ist dann der Fall, wenn die Daten für die Durchführung des Vertrages nicht mehr erforderlich sind. Auch nach Beendigung des Vertrags kann eine Erforderlichkeit, personenbezogene Daten des Vertragspartners zu speichern, bestehen, um vertraglichen oder gesetzlichen Verpflichtungen nachzukommen.
        <br>
        <br>
        Der registrierte Kandidat hat jederzeit die Möglichkeit das Vertragsverhältnis zu beenden. Dies können Sie über Ihren Nutzer-Account oder durch eine entsprechende Mitteilung an uns veranlassen.
        <br>
        <br>

        <h2>13.2 Datenverarbeitung bei Inanspruchnahme eines Karrierecoaches </h2>
        Wir bieten registrierten Kandidaten die Möglichkeit, Ihre Job-Chancen zu erhöhen und sich von einem Karrierecoach unterstützen zu lassen.
        <br>
        <br>
        Karrierecoaches sind freie Handelsvertreter unseres Partners MLP Finanzberatung SE, Alte Heerstraße 40, 69168 Wiesloch (nachfolgend „MLP“). Zur Kontaktaufnahme und Durchführung der angeforderten Leistung übermitteln wir Ihre Kontaktdaten (Vorname, Nachname, E-Mail-Adresse und Telefonnummer) auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO an Ihren Coach. Bitte beachten Sie, dass nach Übertragung der Daten an einen Karrierecoach die Weiterverarbeitung durch diesen als verantwortliche Stelle erfolgt. Der Karrierecoach kann Sie via Telefon, SMS, WhatsApp, E-Mail und Post zu den folgenden Belangen kontaktieren:
        <br>
        <br>
        <ul>
            <li>Bestätigung der Anmeldung</li>
            <li>ggf. Übermittlung von Begleitmaterialien </li>
            <li>Terminierung der gewünschten Leistung</li>
            <li>Erinnerung an Ihren Termin </li>
            <li>Angebot eines Ersatztermins, wenn Sie trotz vereinbartem Termin nicht erscheinen konnten </li>
            <li>Nachfrage, ob bei Ihnen weiterhin ein Interesse am Termin besteht, sofern Sie mehr als zwei Wochen nach Ihrer Anmeldung keinen Termin erhalten haben</li>
        </ul>
        <br>
        <br>
        Diese Übermittlung erfolgt, um die folgenden Leistungen zu erbringen: Begleitung und Coaching im Zuge Ihres Berufseinstiegs.
        <br>
        <br>
        Mit der Inanspruchnahme erlauben Sie darüber hinaus, dass Ihr Coach zur Ausführung der o.g. Leistungen Zugriff auf jegliche Daten und Unterlagen, die Sie auf Talentagent eingeben, erhält.
        <br>
        <br>
        Diesen Zugriff auf Ihr Kandidatenprofil können Sie jederzeit widerrufen, indem Sie uns eine E-Mail an <a href="mailto:info@talentagent.de">info&#64;talentagent.de</a> senden oder das Vertragsverhältnis mit uns beenden, um die Datenverarbeitung für die Zukunft zu beenden. Bei einem Widerruf wird der beschriebene Zugriff auf Ihr Kandidatenprofil für den Coach gesperrt. Darüber hinaus können Sie den Zugriff auf Ihr Kandidatenprofil ebenfalls direkt über Ihren Nutzer-Account entfernen.
        <br>
        <br>
        Darüber hinaus haben Sie ebenfalls gegenüber dem Coach die Rechte nach Punkt 14 ff. dieser Erklärung.

        <br>
        <br>
        <h2>13.3 Datenverarbeitung bei Nachrichten- und Newsletter-Versand an Kandidaten</h2>
        Wir versenden an registrierte Kandidaten Nachrichten per E-Mail, SMS oder WhatsApp an die hierfür im Rahmen des Vertragsschlusses angegebene E-Mail-Adresse bzw. Telefonnummer.
        <br>
        <br>
        Die Verarbeitung dieser Kontaktdaten erfolgt, um Ihnen regelmäßig Erinnerungen an Jobvorschläge, Stellenangebote, interessante Arbeitgeber und ähnliches zukommen zu lassen. Diese Datenverarbeitung ermöglicht eine schnelle und unmittelbare Informationsübermittlung, damit Sie effizient nach Jobs suchen können. Rechtsgrundlage für diese Art der Verarbeitung ist demnach die Erfüllung eines Vertrages mit Ihnen gemäß Art. 6 Abs. 1 lit. b DSGVO.
        <br>
        <br>
        Darüber hinaus verwenden wir Ihre E-Mail-Adresse und Telefonnummer, um Ihnen vertragsbezogene Informationen und Nachrichten zu unseren Leistungen und Angeboten zu übermitteln, wie etwa Änderungen und Neuigkeiten in Ihrem Nutzer-Account. Wir nutzen Ihre Kontaktdaten gegebenenfalls auch für eigene Werbezwecke, um Ihnen ähnliche Dienstleistungen anzubieten, die Sie bereits bei uns in Anspruch genommen haben. Rechtsgrundlage für diese Art der Datenverarbeitung ist Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO, die Sie uns erteilt haben.
        <br>
        <br>
        Für den Nachrichtenversand nutzen wir aktuell verschiedene Dienste, die im nachfolgenden näher beschrieben und aufgeführt sind:
        <ol>
            <li>Nutzung des Dienstes Mailjet und Esendex
            <br>
                <br>
                Für den Versand von Nachrichten per E-Mail und/oder SMS verwenden die Versand- und Kommunikationsdienstleister Mailjet für den Newsletter (SAS Mailjet, 13-13 bis, Rue de l’Aubrac – 75012 Paris, Frankreich) sowie Esendex für SMS-Nachrichten (Commify UK Ltd., 20 Wollaton Street, Nottingham NG1 5FW, England). Die Versanddienstleister nutzen Ihre Daten ausschließlich im Rahmen der Auftragsverarbeitung, zu dessen Zweck zwischen uns und den Versanddienstleistern ein Auftragsverarbeitungsvertrag nach Maßgabe des Art. 28 Abs. 3 S. 1 DS-GVO geschlossen wurde. Die Versanddienstleister nutzen Ihre Daten nicht, um Sie von sich aus anzuschreiben oder diese an Dritte weiterzugeben.
                <br>
                <br>
                Die Versanddienstleister werden auf Grundlage eines berechtigten Interesses gem. Art. 6 Abs. 1 S. 1 lit. f DS-GVO und eines Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1 DS-GVO eingesetzt.
                <br>
                <br>
                Die für die Versanddienstleister geltenden Datenschutzrichtlinien finden Sie unter <a href="https://www.mailjet.de/privacy-policy/">https://www.mailjet.de/privacy-policy/</a> (Mailjet) und unter <a href="https://www.esendex.de/datenschutz">https://www.esendex.de/datenschutz</a> (Esendex).
            </li>
            <br>
            <li>Nutzung des Messenger-Dienstes Twilio
            <br>
            <br>
                Für den Versand von Nachrichten per WhatsApp nutzen wir dafür den Dienstleister Twilio Germany GmbH, Rosenheimer Str. 143C, 81671 München (twilio.com). Dieser Dienstleister erhält von uns die Telefonnummer des Kandidaten für das Einspielen unserer Nachrichten in den Messenger.
                <br>
                <br>
                Twilio agiert als Auftragsverarbeiter. Wir haben einen Auftragsverarbeitungsvertrag (DPA) geschlossen (vgl. <a>https://www.twilio.com/legal/data-protection-addendum</a>), der uns Weisungs- und Kontrollrechte sichert. Sollte ein Datentransfer aus der EU bzw. dem EWR hinaus erfolgen, was grds. in die USA möglich ist, findet dieser statt auf Basis der von der Datenschutzbehörde genehmigten verbindlichen internen Datenschutzvorschriften von Twilio (Binding Corporate Rules, BCR, hier abrufbar: <a href="https://www.twilio.com/legal/binding-corporate-rules">https://www.twilio.com/legal/binding-corporate-rules</a>). Der Transfer ist somit nach Art. 46 Absatz 2 b) i.V.m. Art. 47 DSGVO zulässig.
           <br>
                <br>
                Die Versanddienstleister werden auf Grundlage eines berechtigten Interesses gem. Art. 6 Abs. 1 S. 1 lit. f DS-GVO und eines Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1 DS-GVO eingesetzt.
            </li>
        </ol>
        <br>
        <br>
        Der registrierte Kandidat kann jederzeit seine Einwilligung durch Widerruf mit Wirkung für die Zukunft beenden bzw. in den Einstellungen seines Nutzer-Accounts die Freigabe wieder zurücknehmen und damit die weitere Datenverarbeitung beenden. Der Nutzer kann die Benachrichtigungseinstellungen in seinem Nutzer-Account nach seinem Belieben anpassen.
        <br>
        <br>

        <h2>13.4 Datenverarbeitung bei Bewerbungen auf Stellen von Arbeitgebern</h2>
        Die Bewerberdaten des Kandidaten, das heißt alle relevanten Informationen für eine Bewerbung (neben den im Nutzer-Account angegebenen personenbezogenen Daten ggf. ebenso der vom registrierten Kandidaten bereitgestellte Lebenslauf; nachfolgend zusammengefasst „Kandidatenprofil“) werden im Laufe der vertraglichen Beziehung von uns an denjenigen Arbeitgeber weitergegeben für dessen Stellenangebot sich der registrierte Kandidat beworben hat.
        <br>
        <br>
        Da unsere Hauptleistung und somit auch der Verarbeitungszweck darin besteht Kandidaten und Arbeitgeber zusammenzubringen, gehört auch der Bewerbungsprozess mit der Übermittlung der Bewerbungsdaten zu unserer vertraglichen Aufgabe. Dementsprechend erfolgt die Datenverarbeitung auf Grundlage des zwischen uns und dem registrierten Kandidaten geschlossenen Vertrages, also gemäß Art. 6 Abs. 1 lit. b DSGVO.
        <br>
        <br>
        Für die Datenspeicherung und Datenlöschung auf Seiten des Arbeitgebers ist dieser allein verantwortlich. Der registrierte Kandidat kann jederzeit den betreffenden Arbeitgeber zur Löschung auffordern oder seine übrigen Betroffenenrechte ihm gegenüber geltend machen.
        <br>
        <br>
        Wir selbst speichern die Bewerberdaten so lange, wie das Vertragsverhältnis mit dem Kandidaten andauert und sodann über die übliche Verjährungszeit von drei Jahren, beginnend mit dem Ende des Jahres, in dem der Vertrag zwischen uns und dem Kandidaten beendet wurde.
        <br>
        <br>

        <h2>13.5 Datenverarbeitung des Kandidatenprofils in der internen Suche (Active Sourcing) </h2>
        Hat sich ein Kandidat auf Talentagent registriert, dann wird dessen Kandidatenprofil standardmäßig in der internen Suche für potenzielle Arbeitgeber angezeigt (Active Sourcing für Unternehmen). Dies dient dazu, um unsere vertraglichen Leistungen zu erbringen und Kandidaten und Arbeitgeber zusammenzuführen. Rechtsgrundlage ist demnach die Erfüllung eines zwischen dem registrierten Kandidaten und uns abgeschlossenen Vertrages gemäß Art. 6 Abs. 1 lit. b DSGVO.
        <br>
        <br>
        Solange die vertragliche Beziehung zwischen uns und dem registrierten Kandidaten besteht, werden wir die Daten des Kandidatenprofils verarbeiten und speichern.
        <br>
        <br>
        Der registrierte Kandidat kann diese Möglichkeit jederzeit in seinem Profil ausschalten oder das Vertragsverhältnis mit uns beenden, um die Datenverarbeitung für die Zukunft zu beenden.
        <br>
        <br>
        <h2>13.6 Datenverarbeitung bei Bewerbungen auf Stellen von Personalvermittler-Partnern </h2>
        Um registrierten Kandidaten eine möglichst große Bandbreite an Stellen bereitstellen zu können, kooperieren wir mit verschiedenen Personalvermittler-Partnern, welche ähnliche Leistungen wie wir anbieten (nachfolgend „Partner“).
        <br>
        <br>
        Der registrierte Kandidat, welcher sich auf eine dieser Partner-Stellen bewirbt, wird entweder direkt auf die Plattform des Partners weitergeleitet oder dessen Bewerberdaten werden durch uns an den Partner übermittelt. Die Stellenangebote unserer Partner sind innerhalb von Talentagent gesondert gekennzeichnet. Das heißt, vor Übermittlung der Daten wird der registrierte Kandidat darüber informiert, an welchen unserer Partner dessen Bewerberdaten weitergeleitet werden.
        <br>
        <br>
        Diese Datenverarbeitung dient dem Zweck, den registrierten Kandidaten eine gute, umfangreiche und effektive Leistung anbieten zu können. Die Verarbeitung erfolgt daher auf Grundlage des zwischen uns und dem registrierten Kandidaten geschlossenen Vertrages, also gemäß Art. 6 Abs. 1 lit. b DSGVO.
        <br>
        <br>
        Für die Datenverarbeitung auf Seiten des Partners ist dieser allein verantwortlich. Über Art und Umfang der Datenverarbeitung werden Sie vom Partner noch einmal gesondert informiert. Der registrierte Kandidat kann jederzeit den betreffenden Partner zur Löschung auffordern oder seine übrigen Betroffenenrechte ihm gegenüber geltend machen.
        <br>
        <br>

        <h2>13.7 Automatisiertes CV-Parsing durch OpenAI </h2>
        Zur Analyse und Weiterverarbeitung des Lebenslaufs (nachfolgend "CV-Parsing") des registrierten Kandidaten setzen wir die KI-Technologie des Anbieters OpenAI, 3180 18th Street, San Francisco, USA, ein. OpenAI ist dabei unser Auftragsverarbeiter. Wir haben dazu einen entsprechenden Auftragsverarbeitungsvertrag nach Art. 28 DSGVO in Verbindung mit den EU-Standardvertragsklauseln für den sicheren Transfer von personenbezogenen Daten geschlossen, der uns Weisungs- und Kontrollrechte zusichert. Damit ist der Standard des Datenschutzes und der Datensicherheit im Sinne der DSGVO auch außerhalb der EU gewährleistet. Wir nutzen dieses Tool dabei über eine Programmierschnittstelle. Zu keinem Zeitpunkt werden die personenbezogenen Daten zu Trainingszwecken durch OpenAI verwendet.
        <br>
        <br>
        Nähere Informationen zum Datenschutz können Sie den entsprechenden Richtlinien auf <a href="https://openai.com/policies">https://openai.com/policies</a> entnehmen.
        <br>
        <br>
        Das CV-Parsing ermöglicht uns eine effiziente Verarbeitung großer Mengen von Bewerbungsdokumenten. Dadurch können Bewerbungen in strukturierte Daten verwandelt werden, die wir anschließend filtern, durchsuchen und analysieren können. Das CV-Parsing beruht auf künstlicher Intelligenz.
        <br>
        <br>
        Die Datenverarbeitung erfolgt aufgrund der im Registrierungsprozess erteilten Einwilligung des Nutzers, also gemäß Art. 6 Abs. 1 lit. a DSGVO.
        <br>
        <br>
        Die Datenverarbeitung erfolgt solange die vertragliche Beziehung zwischen uns und dem registrierten Kandidaten besteht. Der registrierte Kandidat kann jederzeit den betreffenden Arbeitgeber, welcher die über das CV-Parsing aufbereiteten Daten übermittelt bekommt, zur Löschung auffordern oder das Vertragsverhältnis mit uns beenden, um die Datenverarbeitung für die Zukunft zu beenden.

        <br>
        <br>
        <h1>14. Datenverarbeitung von Arbeitgebern </h1>
        <h2>14.1 Interessentenansprache potenzieller Arbeitgeber </h2>
        Wir können durch unsere Kunden, Dritte, oder Veröffentlichungen jedweder Art mitbekommen, dass ein Arbeitgeber ggf. einen Bedarf für unsere Produkte und Dienstleistungen hat. Wir werden Geschäftskontaktdaten (wie etwa die Geschäftsemailadresse oder Geschäftstelefonnummer), die personenbezogen sein können, sowie Informationen zum Arbeitgeber in unserem Kundenmanagementsystem (nachfolgend „CRM-System“) hinterlegen und Kontakt zu Arbeitgebern aufnehmen, um deren Bedarf zu ermitteln.
        <br>
        <br>
        Zweck der Datenverarbeitung ist demnach die Neukundenakquise. Rechtsgrundlage stützt sich auf Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO. Wenn und soweit Sie uns keine Einwilligung erteilt haben, ist Rechtsgrundlage unser berechtigtes Interesse gemäß Art. 6 Abs. 1 lit. f DSGVO.
        <br>
        <br>
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind oder die erteilte Einwilligung widerrufen wird.
        <br>
        <br>

        <h2>14.2 Datenverarbeitung zur Aufnahme in Talentagent für Arbeitgeber </h2>
        Wenn und sobald der Arbeitgeber mit uns einen Vertrag schließt, legen wir oder die entsprechenden Mitarbeiter des Arbeitgebers ein Unternehmensprofil auf Talentagent an. Dadurch werden neben auf den Arbeitgeber selbst bezogene Daten (wie etwa Unternehmensname, Webseite, Anschrift) ggf. auch personenbezogene Daten von Mitarbeitern erhoben und verarbeitet (nachfolgend „registrierte Mitarbeiter“).
        <br>
        <br>
        m Rahmen des Registrierungsprozesses über Talentagent werden Sie über die Datenverarbeitung informiert und auf diese Datenschutzerklärung verwiese.
        <br>
        <br>
        Die Anlage des Unternehmensprofils sowie die Verarbeitung personenbezogener Daten registrierter Mitarbeiter ist zur Erfüllung unseres Vertrages, d.h. die Inanspruchnahme unserer vertraglichen Leitung zur Vermittlung von Kandidaten und Arbeitgebern, mit dem Arbeitgeber zwingend erforderlich. Rechtsgrundlage ist somit die Erfüllung eines Vertrages bzw. die Durchführung vorvertraglicher Maßnahmen gemäß Art. 6 Abs. 1 lit. b DSGVO.
        <br>
        <br>
        Der Arbeitgeber hat nach Maßgabe unserer Geschäftsbedingungen jederzeit die Möglichkeit die vertraglichen Beziehungen mit uns zu beenden.
        <br>
        <br>
        Die Daten werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung, der Erfüllung des Vertrages, nicht mehr erforderlich sind. Auch nach Vertragsbeendigung kann es notwendig sein, personenbezogene Daten des Arbeitgebers zu speichern, um vertraglichen oder gesetzlichen Verpflichtungen nachzukommen.

        <br> <br>
        <h2>14.3 Weitere Recruiter-Zugänge anlegen </h2>
        Es ist ebenfalls möglich weitere Recruiter-Zugänge für weitere Mitarbeiter des Arbeitgebers anzulegen. Hierfür müssen alle als erforderlich gekennzeichneten Informationen in die entsprechende Eingabemaske eingetragen werden. Zuvor muss sichergestellt sein, dass die Einwilligung des betreffenden Mitarbeiters zum Anlegen des Recruiter-Zugangs eingeholt wurde.
        <br> <br>

        <h2>14.4 Datenverarbeitung bei Nachrichten- und Newsletter-Versand an registrierte Mitarbeiter </h2>
        Wir versenden an registrierte Mitarbeiter Nachrichten per E-Mail, SMS oder WhatsApp an die hierfür im Rahmen des Vertragsschlusses angegebene E-Mail-Adresse bzw. Telefonnummer.
        <br> <br>
        Die Verarbeitung dieser Kontaktdaten erfolgt, um Ihnen vertragsbezogene Informationen und Nachrichten zu unseren Leistungen und Angeboten zu übermitteln, wie etwa Änderungen und Neuigkeiten in Ihrem Nutzer-Account.
        <br> <br>
        Wir nutzen Ihre Kontaktdaten gegebenenfalls auch für eigene Werbezwecke, um Ihnen ähnliche Dienstleistungen anzubieten, die Sie bereits bei uns in Anspruch genommen haben.
        <br> <br>
        Für den Nachrichtenversand nutzen wir aktuell verschiedene Dienste, die im nachfolgenden näher beschrieben und aufgeführt sind:
        <ol>
            <li>Nutzung des Dienstes Mailjet und Esendex <br><br>
                Für den Versand von Nachrichten per E-Mail und/oder SMS verwenden die Versand- und Kommunikationsdienstleister Mailjet für den Newsletter (SAS Mailjet, 13-13 bis, Rue de l’Aubrac – 75012 Paris, Frankreich) sowie Esendex für SMS-Nachrichten (Commify UK Ltd., 20 Wollaton Street, Nottingham NG1 5FW, England). Die Versanddienstleister nutzen Ihre Daten ausschließlich im Rahmen der Auftragsverarbeitung, zu dessen Zweck zwischen uns und den Versanddienstleistern ein Auftragsverarbeitungsvertrag nach Maßgabe des Art. 28 Abs. 3 S. 1 DS-GVO geschlossen wurde. Die Versanddienstleister nutzen Ihre Daten nicht, um Sie von sich aus anzuschreiben oder diese an Dritte weiterzugeben.
            <br> <br>
                Die Versanddienstleister werden auf Grundlage eines berechtigten Interesses gem. Art. 6 Abs. 1 S. 1 lit. f DS-GVO und eines Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1 DS-GVO eingesetzt.
                <br> <br>
                Die für die Versanddienstleister geltenden Datenschutzrichtlinien finden Sie unter <a href="https://www.mailjet.de/privacy-policy/">https://www.mailjet.de/privacy-policy/</a> (Mailjet) und unter <a href="https://www.esendex.de/datenschutz">https://www.esendex.de/datenschutz</a> (Esendex).
            </li>
            <br>
            <li>
                Nutzung des Messenger-Dienstes Twilio
                <br>
                <br>
                Für den Versand von Nachrichten per WhatsApp nutzen wir dafür den Dienstleister Twilio Germany GmbH, Rosenheimer Str. 143C, 81671 München (twilio.com). Dieser Dienstleister erhält von uns die Telefonnummer des Kandidaten für das Einspielen unserer Nachrichten in den Messenger.
                <br> <br>
                Twilio agiert als Auftragsverarbeiter. Wir haben einen Auftragsverarbeitungsvertrag (DPA) geschlossen (vgl. <a href="https://www.twilio.com/legal/data-protection-addendum">https://www.twilio.com/legal/data-protection-addendum</a>), der uns Weisungs- und Kontrollrechte sichert. Sollte ein Datentransfer aus der EU bzw. dem EWR hinaus erfolgen, was grds. in die USA möglich ist, findet dieser statt auf Basis der von der Datenschutzbehörde genehmigten verbindlichen internen Datenschutzvorschriften von Twilio (Binding Corporate Rules, BCR, hier abrufbar: <a href="https://www.twilio.com/legal/binding-corporate-rules">https://www.twilio.com/legal/binding-corporate-rules</a>). Der Transfer ist somit nach Art. 46 Absatz 2 b) i.V.m. Art. 47 DSGVO zulässig.
            </li>
        </ol>


            <br> <br>
            Die Versanddienstleister werden auf Grundlage eines berechtigten Interesses gem. Art. 6 Abs. 1 S. 1 lit. f DS-GVO und eines Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1 DS-GVO eingesetzt.  Rechtsgrundlage der Datenverarbeitung ist Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO, die Sie uns erteilt haben. Wenn und soweit Sie uns keine Einwilligung erteilt haben, ist Rechtsgrundlage unsere Leistungserbringung und damit die Vertragserfüllung durch uns gemäß Art. 6 Abs. 1 lit. b DSGVO bzw. § 7 Abs. 3 UWG.

        <br> <br>
            <h2>
                14.5 Kommunikation per Telefon
            </h2>
            Zur Abstimmung über Erwartungen und Bedürfnisse der Arbeitgeber sowie deren Anforderungen an Kandidaten setzen wir uns regelmäßig mit den registrierten Mitarbeitern telefonisch in Verbindung. Dies dient ebenfalls dazu den aktuellen Bearbeitungsstand eingegangener Bewerbungen oder möglicher Einstellungen beim Arbeitgeber abzufragen.
            <br> <br>
            Die Kommunikation ist Teil unserer vertraglichen Leistung und dient somit der Erfüllung des zwischen uns und dem Arbeitgeber geschlossenen Vertrages gemäß Art. 6 Abs. 1 lit. b DSGVO.
            <br> <br>

            <h2>Datenverarbeitung im Rahmen von Webinaren für Unternehmen (B2B)</h2>
            Die Verarbeitung Ihrer Daten dient zur Organisation und Durchführung des Webinars.
            <br> <br>
            Die Rechtsgrundlage dafür ist Art. 6 Abs. 1 S. 1 lit. b DS-GVO. Die Verarbeitung ist für die Teilnahme, sohin für die Erfüllung eines Vertrags, dessen Vertragspartei Sie sind, bzw. für die Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin erfolgen, erforderlich (Zur-Verfügung-Stellen des Webinars/Online-Vortrages). Weiterhin ist sie notwendig, um das Webinar bzw. den Online-Vortrag anbieten zu können.
            <br> <br>
            Sie haben ggf. die Möglichkeit, in einem Webinar die Chat-, Fragen- oder Umfragefunktionen zu nutzen. Insoweit werden die von Ihnen gemachten Texteingaben verarbeitet, um diese im Webinar anzuzeigen und ggf. zu protokollieren.
            <br> <br>
            Wir können Sie in Bezug zum Webinar via Telefon, SMS, E-Mail und Post zu folgenden Belangen kontaktieren:
            <br> <br>
            <ul>
                <li>Bestätigung der Anmeldung & Teilnahme</li>
                <li>Ggf. Übermittlung von Begleitmaterialien</li>
                <li>Erinnerung an Ihren Webinartermin</li>
                <li>Angebot eines Ersatzangebots, wenn Sie trotz Anmeldung nicht erscheinen konnten</li>
            </ul>
            <br>
            <br>
            Die Daten der Anmelder werden zweckgebunden für die Verwaltung und Organisation eines Webinars verarbeitet und gelöscht, sobald der Zweck für die Verarbeitung weggefallen ist.
            <br>
            <br>
            Geben uns die Anmelder eine Einwilligung zur Weiterverwendung von Daten für Marketing oder z.B. zum Bezug von E-Mail-Newslettern, so erfolgt in diesem Fall mit Widerruf der Einwilligung die Datenlöschung.
            <br><br>

        <h1>15. Anbindung von Bewerbermanagementsystemen der Arbeitgeber</h1>
        Wir nutzen Dienstleistungen der Kombo Technologies GmbH, ansässig in der Lohmühlenstraße 65, 12435 Berlin, um registrierten Arbeitgebern eine vereinfachte Anbindung ihrer verschiedenen Bewerbermanagementsysteme zu ermöglichen.
        <br>
        <br>
        In Übereinstimmung mit den Vorgaben der DSGVO haben wir mit der Kombo Technologies GmbH eine Auftragsverarbeitungsvereinbarung (AVV) abgeschlossen. Die Kombo Technologies GmbH verpflichtet sich, die anvertrauten Daten sicher zu behandeln. Mehr zum Datenschutz der Kombo Technologies GmbH finden Sie unter folgendem Link: https://www.kombo.dev/privacy-policy
        <br>
        <br>
        Im Rahmen unserer Dienstleistungen für Arbeitgeber, welche über Talentagent Bewerber auf offene Stellenanzeigen akquirieren, werden Bewerberdaten bei Bedarf in das entsprechende HR-Management-System des Arbeitgebers übertragen. Um diesen Datenübertragungsprozess so effizient und unkompliziert wie möglich zu gestalten, setzen wir die Lösungen der Kombo Technologies GmbH ein.
        <br>
        <br>
        Durch die Zustimmung zu unserer Datenschutzerklärung, die im Zuge der Registrierung eines Nutzers erforderlich ist, willigen Sie in die Verarbeitung Ihrer Daten durch die Kombo Technologies GmbH gemäß Art. 6 Abs. 1 lit. a DSGVO ein. Zusätzlich stützen wir uns auf Art. 6 Abs. 1 lit. f DSGVO, da es in unserem berechtigten Interesse liegt, unsere Dienstleistungen entsprechend der Nutzungsbedingungen zu erbringen.
        <br>
        <br>
        Die Kombo Technologies GmbH speichert die Daten möglicherweise kurzzeitig, da dies für die Zusammenarbeit mit bestimmten HR-Managementsystemen erforderlich sein kann. Die Daten werden gelöscht, sobald der Zweck ihrer Erhebung entfällt. Zudem gewährleistet die Kombo Technologies GmbH den Schutz ihrer Datenbanken durch eine ISO-Zertifizierung.
        <br>
        <br>

        <h1>16. Ihre Rechte als betroffene Person</h1>
        <h2>16.1 Recht auf Bestätigung</h2>
        Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten
        verarbeitet werden.
        <br>
        <br>

        <h2>16.2 Recht auf Auskunft Art. 15 DS-GVO</h2>
        Sie haben das Recht, jederzeit von uns unentgeltliche Auskunft über die zu Ihrer Person gespeicherten
        personenbezogenen Daten sowie eine Kopie dieser Daten nach Maßgabe der gesetzlichen Bestimmungen zu erhalten.
        <br>
        <br>

        <h2>16.3 Recht auf Berichtigung Art. 16 DS-GVO</h2>
        Sie haben das Recht, die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner
        steht Ihnen das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung, die Vervollständigung
        unvollständiger personenbezogener Daten zu verlangen.
        <br>
        <br>

        <h2>16.4 Löschung Art. 17 DS-GVO</h2>
        Sie haben das Recht, von uns zu verlangen, dass die Sie betreffenden personenbezogenen Daten unverzüglich
        gelöscht werden, sofern einer der gesetzlich vorgesehenen Gründe zutrifft und soweit die Verarbeitung bzw.
        Speicherung nicht erforderlich ist.
        <br>
        <br>

        <h2>16.5 Einschränkung der Verarbeitung Art. 18 DS-GVO</h2>
        Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der gesetzlichen
        Voraussetzungen gegeben ist.
        <br>
        <br>

        <h2>16.6 Datenübertragbarkeit Art. 20 DS-GVO</h2>
        Sie haben das Recht, die Sie betreffenden personenbezogenen Daten, welche uns durch Sie bereitgestellt wurden,
        in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie haben außerdem das Recht, diese
        Daten einem anderen Verantwortlichen ohne Behinderung durch uns, dem die personenbezogenen Daten bereitgestellt
        wurden, zu übermitteln, sofern die Verarbeitung auf der Einwilligung gemäß Art. 6 Abs. 1 lit. a DS-GVO oder Art.
        9 Abs. 2 lit. a DS-GVO oder auf einem Vertrag gemäß Art. 6 Abs. 1 lit. b DS-GVO beruht und die Verarbeitung
        mithilfe automatisierter Verfahren erfolgt, sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe
        erforderlich ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche uns
        übertragen wurde.
        <br>
        <br>
        Ferner haben Sie bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO das Recht,
        zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen
        Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und
        Freiheiten anderer Personen beeinträchtigt werden.
        <br>
        <br>
        <h2>16.7 Widerspruch Art. 21 DS-GVO</h2>
        Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die
        Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 lit. e (Datenverarbeitung
        im öffentlichen Interesse) oder f (Datenverarbeitung auf Grundlage einer Interessenabwägung) DS-GVO erfolgt,
        Widerspruch einzulegen.
        <br>
        <br>
        Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling im Sinne von Art. 4 Nr. 4 DS-GVO.
        <br>
        <br>

        Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir
        können zwingende berechtigte Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten
        überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
        <br>
        <br>
        In Einzelfällen verarbeiten wir personenbezogene Daten, um Direktwerbung zu betreiben. Sie können jederzeit
        Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung einlegen. Dies gilt
        auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht. Widersprechen Sie gegenüber uns
        der Verarbeitung für Zwecke der Direktwerbung, so werden wir die personenbezogenen Daten nicht mehr für diese
        Zwecke verarbeiten.
        <br>
        <br>

        Zudem haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, gegen die Sie
        betreffende Verarbeitung personenbezogener Daten, die bei uns zu wissenschaftlichen oder historischen
        Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DS-GVO erfolgen, Widerspruch einzulegen, es
        sei denn, eine solche Verarbeitung ist zur Erfüllung einer im öffentlichen Interesse liegenden Aufgabe
        erforderlich.
        <br>
        <br>
        Ihnen steht es frei, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft, ungeachtet der
        Richtlinie 2002/58/EG, Ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische
        Spezifikationen verwendet werden.
        <br>
        <br>
        <h2>16.8 Widerruf einer datenschutzrechtlichen Einwilligung</h2>
        Sie haben das Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit mit Wirkung für die
        Zukunft zu widerrufen.
        <br>
        <br>

        <h2>16.9 Beschwerde bei einer Aufsichtsbehörde</h2>
        Sie haben das Recht, sich bei einer für Datenschutz zuständigen Aufsichtsbehörde über unsere Verarbeitung
        personenbezogener Daten zu beschweren.
        <br>
        <br>

        <h1>17. Routinemäßige Speicherung, Löschung und Sperrung von personenbezogenen Daten</h1>
        Wir verarbeiten und speichern Ihre personenbezogenen Daten nur für den Zeitraum, der zur Erreichung des
        Speicherungszwecks erforderlich ist oder sofern dies durch die Rechtsvorschriften, welchen unser Unternehmen
        unterliegt, vorgesehen wurde.
        <br>
        <br>

        Entfällt der Speicherungszweck oder läuft eine vorgeschriebene Speicherfrist ab, werden die personenbezogenen
        Daten routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
        <br>
        <br>

        <h1>18. Dauer der Speicherung von personenbezogenen Daten</h1>
        Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzliche
        Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten routinemäßig gelöscht, sofern sie
        nicht mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind
    </div>

    <div class="flex column content-width card-shadow">
        <h1>Einwilligung in die Datenverarbeitung und -nutzung</h1>
        Mit der Bestätigung der Checkbox bei der Anmeldung/Registrierung oder Buchung des von Ihnen ausgewählten
        Angebots willigen Sie ein, dass die von Ihnen in der Anmeldemaske erhobenen Daten sowie die IP-Adresse wie folgt
        verwendet werden können:

        Wir sind berechtigt Ihnen per Post, SMS, Mail, WhatsApp oder Telefon Informationen zu Angeboten zukommen zu
        lassen, und sie herzu einzuladen. Bei diesen Angeboten handelt es sich um Mehrwertangebote für Studenten unserer
        Plattform "Hochschulinitiative-Deutschland"(www.hochschulinitiative-deutschland.de) oder "Talentagent"
        (www.talentagent.de) Sie können dieser Einwilligung jederzeit durch eine formlose Mail an info&#64;talentagent.de
        widersprechen.
    </div>

    <div class="flex column content-width card-shadow">
        <h1>Einwilligung in die Datenverarbeitung und -nutzung bei Anmeldung zu einem B2B-Webinar</h1>
        Mit der Bestätigung der Checkbox bei der Anmeldung/Registrierung oder Buchung des von Ihnen ausgewählten
        Angebots willigen Sie ein, dass die von Ihnen in der Anmeldemaske erhobenen Daten sowie die IP-Adresse wir folgt
        verwendet werden können: Wir sind berechtigt Ihnen per Post, SMS, E-Mail, WhatsApp oder Telefon Informationen
        und Insights zur Absolventenzielgruppe sowie Informationen zu weiteren relevanten Angeboten im Bereich
        Einstiegsjobvermittlung zukommen zu lassen und Sie hierzu ggf. einzuladen. Sie können dieser Einwilligung
        jederzeit durch eine formlose E-Mail an info&#64;talentagent.de widersprechen.
    </div>
</div>
