import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {TalentCoachRequestDistributionData, UnisignAccountRegistrationSuccessData, CpdTalentCreationData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.talent.onboarding.CpdTalentOnboardingController
 */

@Injectable()
@ResourceParams({})
export class CpdTalentOnboardingResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Post,
        path: '/onboarding/cpd/account',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _createOrUpdateTalentAccount: IResourceMethodObservableStrict<CpdTalentCreationData, null, null, UnisignAccountRegistrationSuccessData> | undefined
    createOrUpdateTalentAccount(requestBody: CpdTalentCreationData): Promise<UnisignAccountRegistrationSuccessData> {
        if (!this._createOrUpdateTalentAccount) throw new Error("resource has not been properly initialized")
        return this._createOrUpdateTalentAccount(requestBody, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/onboarding/cpd/distribution',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _updateDistributionCriteriaForCoachRequest: IResourceMethodObservableStrict<TalentCoachRequestDistributionData, null, null, null> | undefined
    updateDistributionCriteriaForCoachRequest(requestBody: TalentCoachRequestDistributionData): Promise<null> {
        if (!this._updateDistributionCriteriaForCoachRequest) throw new Error("resource has not been properly initialized")
        return this._updateDistributionCriteriaForCoachRequest(requestBody, null, null).toPromise()
    }

}