<a class="anchor-wrapper" routerLink="/talent/karriereguide/{{ careerGuide.routingUrl }}">
  <div class="card-flat width-100 flex row start-center height-100 gap-lg" *ngIf="size == 'Small'" >
    <img class="small-image" [src]="bannerCardImage" alt="">
    <div class="flex column flex-space-between height-100 width-50">
      <div class="bold-text">{{ careerGuide.title }}</div>
      <a class="grey-anchor" routerLink="/talent/karriereguide/{{ careerGuide.routingUrl }}">WEITERLESEN</a>
    </div>
  </div>

  <div class="card-flat width-100 flex column gap-lg" *ngIf="size != 'Small'" [ngClass]="{'medium-card': size == 'Medium'}">
    <img class="normal-image" [src]="bannerCardImage" alt="">
    <div class="bold-text">{{ careerGuide.title }}</div>
    <div *ngIf="size == 'Large'">{{ careerGuide.metaDescription }}</div>
    <a class="grey-anchor" routerLink="/talent/karriereguide/{{ careerGuide.routingUrl }}">WEITERLESEN</a>
  </div>
</a>

